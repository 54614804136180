import BigNumber from "bignumber.js"
import { getGiverVaultContract } from "../../utils/getContract"



export const fetchGiverVaultDynamic = async(gVaultAdr : string) => {

    const gVault = getGiverVaultContract(gVaultAdr)

    const gTotalShares = await gVault.getGTotalShares()

    return new BigNumber(gTotalShares.toString())
}