import { createContext, useEffect, useRef, useState } from "react";

const TIMER = 60000
const FASTTIMER = 10000

const TimedRefreshContext = createContext({ refreshTimer: 0, refreshFastTimer: 0 })

const useIsBrowserTabActive = () => {
    const isBrowserTabActiveRef = useRef(true)

    useEffect(() => {
        const onVisibilityChange = () => {
            isBrowserTabActiveRef.current = !document.hidden
        }

        window.addEventListener('visibilitychange', onVisibilityChange)

        return () => {
            window.removeEventListener('visibilitychange', onVisibilityChange)
        }
    }, [])

    return isBrowserTabActiveRef
}

const TimedRefreshProvider = ({ children }: any) => {

    const [refreshTimer, setRefreshTimer] = useState(0)
    const [refreshFastTimer, setRefreshFastTimer] = useState(0)
    const isBrowserTabActiveRef = useIsBrowserTabActive()


    useEffect(() => {

        const ID = setInterval(async () => {
            if (isBrowserTabActiveRef.current) {
                console.log('TIMEDREFRESH CONTEXT: Tick.')
                setRefreshTimer((prev) => prev + 1)
            }
        }, TIMER)

        return () => clearInterval(ID)
    }, [isBrowserTabActiveRef])

    useEffect(() => {

        const ID = setInterval(async () => {
            if (isBrowserTabActiveRef.current) {
                console.log('TIMEDREFRESH CONTEXT: FAST Tick.')
                setRefreshFastTimer((prev) => prev + 1)
            }
        }, FASTTIMER)

        return () => clearInterval(ID)
    }, [isBrowserTabActiveRef])

    return <TimedRefreshContext.Provider value={{ refreshTimer: refreshTimer, refreshFastTimer: refreshFastTimer }}>{children}</TimedRefreshContext.Provider>

}

export { TimedRefreshContext, TimedRefreshProvider }