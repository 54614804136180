import { getMultiCallContract } from "./getContract"
import { ethers } from "ethers"


export interface Call {
    address: string // Address of the contract
    name: string // Function name on the contract (example: balanceOf)
    params?: any[] // Function params
  }
  
  interface MulticallOptions {
    requireSuccess?: boolean
  }

export type MultiCallResponse<T> = T | null

export const multicallv2 = async <T = any>(
    abi: any[],
    calls: Call[],
    options: MulticallOptions = { requireSuccess: true },
  ): Promise<MultiCallResponse<T>> => {

    const { requireSuccess } = options

    const multi = getMultiCallContract()
    
    const itf = new ethers.utils.Interface(abi)
  
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const returnData = await multi.tryAggregate(requireSuccess, calldata)
    const res = returnData.map((call: any, i: number) => {
      const [result, data] = call
      return result ? itf.decodeFunctionResult(calls[i].name, data) : null
    })
  
    return res
  }