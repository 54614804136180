import BigNumber from "bignumber.js";
import { getWrappedBaseAdr } from "../../utils/getAddress";
import { getGasEstimateAndRun } from "../getGasEstimate";


export const callRouterAdd = async (routerContract: any, adr0: string, adr1: string, amount0: BigNumber, amount1: BigNumber, deadline: number) => {

    const signerAdr = await routerContract.signer?.getAddress()

    //Detecter les bases tokens
    const isBase0 = adr0 === getWrappedBaseAdr()
    const isBase1 = adr1 === getWrappedBaseAdr()

    const minAmount0 = amount0.times(new BigNumber(0.99)).toFixed(0, 1)

    const minAmount1 = amount1.times(new BigNumber(0.99)).toFixed(0, 1)

    if (isBase0) {
        const value = amount0.toFixed(0, 1)

        const returnValue = await getGasEstimateAndRun(routerContract, 'addLiquidityETH', [adr1, amount1.toFixed(0, 1), minAmount1, minAmount0, signerAdr, deadline], 1100, { value })
        return returnValue
    }
    else if (isBase1) {
        const value = amount1.toFixed(0, 1)

        const returnValue = await getGasEstimateAndRun(routerContract, 'addLiquidityETH', [adr0, amount0.toFixed(0, 1), minAmount0, minAmount1, signerAdr, deadline], 1100, { value })
        return returnValue
    }
    else {

        const returnValue = await getGasEstimateAndRun(routerContract, 'addLiquidity', [adr0, adr1, amount0.toFixed(0, 1), minAmount0, amount1.toFixed(0, 1), minAmount1, signerAdr, deadline], 1100)
       return returnValue
    }
}