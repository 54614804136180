import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { LTSData } from "../../types/ltsTypes";
import { useRouterAdd } from "../../hooks/actionHooks/useRouterAdd";
import { useTokenApprove } from "../../hooks/actionHooks/useTokenApprove";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useTokenAllowance from "../../hooks/useTokenAllowance";
import  { TokenBalanceData } from "../../hooks/useTokenBalance";
import { formatNumberTo2DeciFloor } from "../../utils/formatNumber";
import { BIG_POW_X } from "../../utils/utilsBigNumber";
import { RadFiPromiseButton } from "../StyledComponent/Buttons";
import { LegendNumberInline } from "../StyledComponent/LegendNumber";
import TokenTextField from "./components/TokenTextField";
import { styleCardLiqBorder } from "./style/styled";


export type CardLiquidityAddProps = {
    ltsData: LTSData,
    value: number, 
    balance0 : TokenBalanceData,
    balance1 : TokenBalanceData,
    lpBalance: TokenBalanceData
}
export const CardLiquidityAdd: React.FC<CardLiquidityAddProps> = ({ ltsData, value, balance0, balance1, lpBalance }) => {

    const { account } = useActiveWeb3React()

    const approve0 = useTokenAllowance(ltsData.gToken0Infos.adr, account, ltsData.gRouterAdr)
    const approve1 = useTokenAllowance(ltsData.gToken1Infos.adr, account, ltsData.gRouterAdr)

    const readableBalance0 = balance0.balance.div(BIG_POW_X(ltsData.gToken0Infos.decimals))
    const readableBalance1 = balance1.balance.div(BIG_POW_X(ltsData.gToken1Infos.decimals))

    const [value0, setValue0] = useState<BigNumber>(new BigNumber(0))
    const [value1, setValue1] = useState<BigNumber>(new BigNumber(0))

    const [forced0, setForced0] = useState<undefined | string>(undefined)
    const [forced1, setForced1] = useState<undefined | string>(undefined)

    const AtoBMultiplier = ltsData.gWantReserve1.div(ltsData.gWantReserve0)
    const BtoAMultiplier = ltsData.gWantReserve0.div(ltsData.gWantReserve1)

    const handleValueChange0 = (newValue0: BigNumber) => {
        setForced0(undefined)
        setValue0(newValue0)

        const tmpVal = newValue0.times(AtoBMultiplier)
        //!!!! NE PAS OUBLIER LE PRISE EN COMPTE DE DECI DANS LE CALCUL
        setForced1(formatNumberTo2DeciFloor(tmpVal.div(BIG_POW_X(ltsData.gToken1Infos.decimals))))
        setValue1(tmpVal)

        console.log('Value0 ' + newValue0)
        console.log('Value1 ' + tmpVal)
    }

    const handleValueChange1 = (newValue1: BigNumber) => {
        setForced1(undefined)
        setValue1(newValue1)

        const tmpVal = newValue1.times(BtoAMultiplier)

        setForced0(formatNumberTo2DeciFloor(tmpVal.div(BIG_POW_X(ltsData.gToken0Infos.decimals))))
        setValue0(tmpVal)



        console.log('Value0 ' + tmpVal)
        console.log('Value1 ' + newValue1)
    }

    const { onAdd } = useRouterAdd(ltsData.gRouterAdr, ltsData.gToken0Infos.adr, ltsData.gToken1Infos.adr)

    const handleAddButton = async () => {
        console.log('Selected Value : token0 : ' + value0)
        console.log('Selected Value : token1 : ' + value1)

        await onAdd(value0, value1)

        //Refresh des values
        balance0.refresher()
        balance1.refresher()
        lpBalance.refresher()
    }

    const approve0Obj = useTokenApprove(ltsData.gToken0Infos.adr, ltsData.gRouterAdr)
    const approve1Obj = useTokenApprove( ltsData.gToken1Infos.adr, ltsData.gRouterAdr)

    const approveButton0 = async (selected: number) => {
        const result = await approve0Obj.onApprove()
        console.log(result)
        approve0.callApproveRefresh()
    }

    const approveButton1 = async () => {
        const result = await approve1Obj.onApprove()
        console.log(result)
        approve1.callApproveRefresh()
    }

    const disableAddButton = () => {
        const AError = value0.gt(balance0.balance)
        const BError = value1.gt(balance1.balance)

        return AError || BError
    }

    const choosenButtons = () => {
        if (approve0.approve.isGreaterThan(0) && approve1.approve.gt(0)) {
            return (
                <RadFiPromiseButton label='ADD' promiseAction={handleAddButton} disabled={disableAddButton()} />
            )
        }
        else {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <RadFiPromiseButton promiseAction={approveButton0} disabled={approve0.approve.gt(0)} label={'APPROVE ' + ltsData.gToken0Infos.symbol} />
                    <RadFiPromiseButton promiseAction={approveButton1} disabled={approve1.approve.gt(0)} label={'APPROVE ' + ltsData.gToken1Infos.symbol} />
                </Box>
            )
        }
    }

    const label0 = <LegendNumberInline legend={ltsData.gToken0Infos.symbol + ' balance: '} number={formatNumberTo2DeciFloor(readableBalance0)} />
    const label1 = <LegendNumberInline legend={ltsData.gToken1Infos.symbol + ' balance: '} number={formatNumberTo2DeciFloor(readableBalance1)} />

    return (
        <Box hidden={value !== 0}>
            <Box sx={{ display: 'flex', mx: 1 }}>
                <Typography variant='h5' sx={{ p: 0, lineHeight: 1 }}>DEPOSIT LIQUIDITY TO GENERATE LP-TOKENS</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mx: 1, pt: 1, ...styleCardLiqBorder
            }}>
                <TokenTextField maxValue={balance0.balance} tokenDeci={ltsData.gToken0Infos.decimals} valueSetter={handleValueChange0} forcedValue={forced0} label={label0} symbol={ltsData.gToken0Infos.symbol} />
                <TokenTextField maxValue={balance1.balance} tokenDeci={ltsData.gToken1Infos.decimals} valueSetter={handleValueChange1} forcedValue={forced1} label={label1} symbol={ltsData.gToken1Infos.symbol} />
            </Box>

            <Box sx={{ m: 1 }}>
                {choosenButtons()}
            </Box>
        </Box>
    )
};

export default CardLiquidityAdd;