import { Switch, Typography } from "@mui/material"
import { Box } from "@mui/system"


export const SwitchFilter = (props: any) => {

    const { filter, setFilter } = props

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems:'center' }}>
            <Typography variant='body2'>only staked: </Typography>
            <Switch checked={filter} onChange={() => setFilter((prev: any) => !prev)} />
        </Box>
    )
}