import { ArrowDropDownRounded, DoubleArrowRounded } from "@mui/icons-material";
import { Grid, Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { LTSData } from "../../types/ltsTypes";
import { SignatureData } from "../../functions/userActions/callPermitForWithdraw";
import { useCreatePermitOrApprove } from "../../hooks/actionHooks/useCreatePermitOrApprove";
import { useRouterRemove } from "../../hooks/actionHooks/useRouterRemove";
import  { TokenBalanceData } from "../../hooks/useTokenBalance";
import { formatNumberTo2DeciFloor } from "../../utils/formatNumber";
import { BIG_POW18, BIG_POW_X } from "../../utils/utilsBigNumber";
import { RadFiPromiseButton } from "../StyledComponent/Buttons";
import { LegendNumberInline } from "../StyledComponent/LegendNumber";
import { TokenIconBalance } from "../StyledComponent/TokenIconBalance";
import { styleCardLiqBorder, styleCardLiqSlider } from "./style/styled";

export type CardLiquidityRemoveProps = {
    ltsData: LTSData
    value: number,
    lpBalance: TokenBalanceData,
    balance0: TokenBalanceData,
    balance1: TokenBalanceData
}
export const CardLiquidityRemove: React.FC<CardLiquidityRemoveProps> = ({ ltsData, value, lpBalance, balance0, balance1 }) => {

    //const { account } = useActiveWeb3React()

    //const lpBalance = useTokenBalance(stratData.lpAdr, account)

    const readableLPBalance = lpBalance.balance.div(BIG_POW18)

    const tokenValue0 = ltsData.gWantReserve0.div(ltsData.gWantTotalSupply)
    const tokenValue1 = ltsData.gWantReserve1.div(ltsData.gWantTotalSupply)


    const [permitSignature, setPermitSig] = useState<SignatureData | undefined>(undefined)

    const [sliderValue, setSlider] = useState<number>(0)

    const { onPermit } = useCreatePermitOrApprove(ltsData.gRouterAdr, ltsData.gWantAdr)
    const { onRemove } = useRouterRemove(ltsData.gRouterAdr, ltsData.gToken0Infos.adr, ltsData.gToken1Infos.adr)

    const lpQty = readableLPBalance.times(sliderValue).div(new BigNumber(100))

    const t0Qty = lpBalance.balance.times((sliderValue / 100)).times(tokenValue0)
    const t1Qty = lpBalance.balance.times((sliderValue / 100)).times(tokenValue1)

    const readablet0Qty = t0Qty.div(BIG_POW_X(ltsData.gToken0Infos.decimals))
    const readablet1Qty = t1Qty.div(BIG_POW_X(ltsData.gToken1Infos.decimals))

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        if (typeof newValue === "number") {
            setSlider(newValue)
            setPermitSig(undefined)
        }
    }

    const handleOnPermit = async () => {
        const permit = await onPermit(lpBalance.balance.times((sliderValue / 100)))
        setPermitSig(permit)
    }

    const handleOnRemove = async () => {
        const lpQty = lpBalance.balance.times(sliderValue).div(new BigNumber(100))
        const tx = await onRemove(lpQty, t0Qty, t1Qty, permitSignature as SignatureData)

        balance0.refresher()
        balance1.refresher()
        lpBalance.refresher()
        console.log(tx)
    }

    return (
        <Box hidden={value !== 1}>
            <Box sx={{ display: 'flex', mx: 1 }}>
                <Typography variant='h5' sx={{ p: 0 }}>CONVERT BACK LP-TOKENS TO CURRENCIES</Typography>
            </Box>
            <Grid container alignItems='stretch'>
                <Grid item xs={12} md={8}>
                    <Box sx={{ mx: 1, p: 0.5, height: '100%', ...styleCardLiqBorder }}>
                        <Box sx={{ px: 1, display: 'flex' }}>
                            <Slider
                                value={sliderValue}
                                onChange={handleSliderChange}
                                step={10}
                                sx={styleCardLiqSlider} />
                            <Typography sx={{ ml: 1 }} variant='body1'>{sliderValue}%</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <LegendNumberInline legend='Balance:' number={'LP ' + formatNumberTo2DeciFloor(readableLPBalance)} />
                            <LegendNumberInline legend='To convert:' number={'LP ' + formatNumberTo2DeciFloor(lpQty)} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <DoubleArrowRounded />
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <ArrowDropDownRounded />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs md>
                    <Box sx={{ display: { xs: 'flex', md: 'block' }, justifyContent: 'space-evenly', mx: 1, p: 0.5, ...styleCardLiqBorder }}>
                        <TokenIconBalance tokenAdr={ltsData.gToken0Infos.adr} qty={readablet0Qty} />
                        <TokenIconBalance tokenAdr={ltsData.gToken1Infos.adr} qty={readablet1Qty} />
                    </Box>
                </Grid>

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ mr: 4 }}>
                    <RadFiPromiseButton promiseAction={handleOnPermit} label='PERMIT' />
                </Box>
                <Box sx={{ ml: 4 }}>
                    <RadFiPromiseButton promiseAction={handleOnRemove} label='REMOVE' disabled={permitSignature === undefined} />
                </Box>
            </Box>
        </Box >
    )
};

export default CardLiquidityRemove;