
import { LTSStaticData } from "../../types/ltsTypes"
import LTSAdrList from "../../values/LTSAdrList.json"
import { fetchLTSStaticData } from "./fetchLTSStaticData"

export const fetchAllLTSStatic = async(): Promise<LTSStaticData[]> => {

    const LTSList: LTSStaticData[] = await Promise.all(LTSAdrList.map((adr: string) => fetchLTSStaticData(adr)))

    return LTSList
}