import { GitHub, LibraryBooks, Twitter } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"



export const PageFooter = () => {

    return (
        <Box >
            <Typography variant='body2'>Copyright RDPRT 2022</Typography>


            <a href="https://twitter.com/RadFi_" target='_blank' rel="noreferrer"><Twitter/></a>
            <GitHub/>
            
            <a href="https://rdprt-aggregator.gitbook.io/radfi_wiki/" target='_blank' rel="noreferrer"><LibraryBooks/></a>

        </Box>
    )

}