//Return a contract binded to the injected Provider or the default one
import { ethers } from "ethers";
import uniswapV2PairAbi from "../values/abi/abi_uniswapV2Pair.json";
import vaultManagerAbi from "../values/abi/abi_vaultManager.json";
import strategyMCAbi from "../values/abi/abi_strategyMasterChef.json";
import uniswapV2RouterAbi from "../values/abi/abi_uniswapV2Router.json";
import uniswapV2factoryAbi from "../values/abi/abi_uniswapV2factory.json";
import aggregatorV3InterfaceAbi from "../values/abi/abi_aggregatorV3Interface.json"
import giverVaultAbi from "../values/abi/abi_giverVault.json"
import indexedVaultAbi from "../values/abi/abi_indexedVault.json"
import erc20Abi from "../values/abi/abi_erc20.json";
import multiCall2Abi from "../values/abi/abi_multiCall.json"
import radFiMinterAbi from "../values/abi/abi_radfiMinter.json"
import { getMultiCallAdr, getVaultManagerAddress } from "./getAddress";
import { simpleRPCProvider } from "./simpleRPCProvider";
import { getMasterChiefAbi } from "../values/getMasterChefMethods";

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    const contractBind = signer ?? simpleRPCProvider
    return new ethers.Contract(address, abi, contractBind)
}

export const getVaultManagerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(vaultManagerAbi, getVaultManagerAddress(), signer)
}

export const getMultiCallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(multiCall2Abi, getMultiCallAdr(), signer)
}

export const getSpecificMasterChefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(getMasterChiefAbi(address), address, signer)
}

export const getSpecificRouterContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(uniswapV2RouterAbi, address, signer)
}

export const getSpecificFactoryContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(uniswapV2factoryAbi, address, signer)
}

export const getChainLinkPriceFeedContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(aggregatorV3InterfaceAbi, address, signer)
}

export const getPairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(uniswapV2PairAbi, address, signer)
}

export const getStrategyMCContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(strategyMCAbi, address, signer)
}

export const getGiverVaultContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(giverVaultAbi, address, signer)
}

export const getIndexedVaultContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(indexedVaultAbi, address, signer)
}

export const getRadFiMinterContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(radFiMinterAbi, address, signer)
}

export const getERC20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(erc20Abi, address, signer)
}

