import { AppBar, Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ChainNameBadge from "../../components/ChainNameBadge";
import { RadFiMenuButton } from "../StyledComponent/Buttons";
import ConnectButton from "./components/ConnectButton";
import { styleAppBarGlobal, styleAppBarInsideBox, styleAppBarRightBox } from "./style/styled";


export type TopNavBarProp = {
    menuState: [number, React.Dispatch<React.SetStateAction<number>>]
}

export const TopNavBar: React.FC<TopNavBarProp> = ({menuState}) => {
    console.log('TopNavBar')

    return (
        <AppBar sx={styleAppBarGlobal}>
            <Box sx={styleAppBarInsideBox}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
              
                        <Avatar sx={{ width:42, height: 42, mr: 1 }} src="/img/LogoRadFi_V1.png" />
                        <Typography variant='h1'>
                            RADFI
                        </Typography>
                        <Typography sx={{ marginLeft: '5px' }} variant='h3' noWrap>
                            network
                        </Typography>
                        <Typography sx={{ marginLeft: '5px' }} variant='h5' noWrap>
                            BETA
                        </Typography>
                    

                    <RadFiMenuButton label='FARMS' activated={menuState[0] === 0} buttonAction={() => menuState[1](0)}/>
                    <RadFiMenuButton label='EARN RADFI' activated={menuState[0] === 1} buttonAction={() => menuState[1](1)}/>
                    {/* <Box sx={{ display: 'flex', ml: '36px' }}>
                        <Tooltip title='Coming soon' arrow>
                            <Typography variant="h1" sx={{ mr: '10px', color:(theme: Theme) => theme.palette.neutral.dark}}>DASHBOARD</Typography>
                        </Tooltip>
                        <Link color="secondary">
                            <Typography variant='h1'>STRATEGIES</Typography>
                        </Link>
                    </Box> */}
                </Box>

                <Box sx={styleAppBarRightBox}>
                    <ConnectButton />
                    <ChainNameBadge />
                </Box>
            </Box>
        </AppBar>
    )
};

export default TopNavBar;
