import BigNumber from "bignumber.js"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { SignatureData } from "../../functions/userActions/callPermitForWithdraw"
import { callRouterWithdrawWithPermit } from "../../functions/userActions/callRouterWithdraw"
import { useSpecificRouter } from "../useContract"


export const useRouterRemove = (routerAdr: string, token0Adr: string, token1Adr: string) => {

    const routerContract = useSpecificRouter(routerAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleRemove = useCallback(
        async (amountLiq: BigNumber, amount0: BigNumber, amount1: BigNumber, sigData: SignatureData) => {
            
            const txhash = await callRouterWithdrawWithPermit(routerContract, amountLiq, token0Adr, token1Adr, amount0, amount1, sigData)
            console.info('TRX result is : '  + txhash)

            if(txhash.toString() === '1'){
                enqueueSnackbar('LP tokens converted back!', {variant:'success'})
            }
        }, [routerContract, token0Adr, token1Adr, enqueueSnackbar]
    )

    return { onRemove: handleRemove }
}