import BigNumber from "bignumber.js"
import { LTSData } from "../types/ltsTypes"
import { APYData } from "./calculateAPY"



//!!!!!! Attention c'est pas de l'APY, mais de l'APR
export const CalculateAPYLTS = (ltsData: LTSData, fixedApr: number): APYData => {

    const BN = BigNumber.clone({ POW_PRECISION: 20, DECIMAL_PLACES: 20 })

    const gainPoolPerQuarter = new BN(ltsData.gMCAllocPoint / ltsData.gMCTotalAlloc).times(new BN(60 * 10)).times(ltsData.gMCRewardPerSec)

    const gainPerSharePerQuarter = gainPoolPerQuarter.div(ltsData.gMCEarnTotalShares)

    //Reward in BUSD : RewardPerPool * RewardPriceInBUSD
    const rInBusd = ltsData.gEarnTokenPrice.times(gainPerSharePerQuarter)
    //GaininPercent : 1 + RewardInBusd / pTokenValueInBUSD

    const gainInPercent = (rInBusd.div(ltsData.gWantTokenPrice))


    const numberOfQuarterPerYear = new BN(365 * 24 * 6)

    const stableultima = (gainInPercent.times(numberOfQuarterPerYear)).times(new BN(100)).times(new BN(0.95)).plus(new BN(4.5))
    const ultima = (gainInPercent.times(numberOfQuarterPerYear)).times(new BN(100)).times(new BN(0.95)).plus(new BN(fixedApr)).plus(new BN(4.5))

    return {
        ultima: ultima,
        stableAPR: stableultima,
        feesAPR: new BigNumber(fixedApr)
    }
}

export default CalculateAPYLTS