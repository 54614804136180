import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import MaterialCard from "../StyledComponent/MaterialCard";
import CardLeft from "./CardLeft";
import CardTabPanel from "./CardTabPanel";



export const StrategyCard = (props: any) => {

    const { ltsData } = props

    return (
        <Box sx={{ py:2}}>
            <MaterialCard>
                <Grid container>
                    <Grid item xs={12} sm={3}>
                        <CardLeft ltsData={ltsData} />
                    </Grid>

                    <Grid item xs>
                        <CardTabPanel ltsData={ltsData} />
                    </Grid>

                </Grid>
            </MaterialCard>
        </Box >

    )
};

export default StrategyCard;