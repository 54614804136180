import BigNumber from "bignumber.js"
import { Call, multicallv2 } from "../../utils/multicall"
import { getMasterChiefAbiAsAny, getMasterChiefMethods } from "../../values/getMasterChefMethods"

export type PoolInfoMasterChefData = {
    rewardAdr: string,
    totalRewardPerSec: BigNumber,
    totalAllocPoint: number,
    poolAllocPoint: number,
}

//Maybe add newentry to masterChef JSon (pendingBoos, pendingCakes, pending etc, BooPerSecond, CakePerBlock...)
const fetchPoolInfoMasterChef = async (masterChefAdr: string, pid: number): Promise<PoolInfoMasterChefData> => {
 
    //Faire un multicall ici
    const masterChefAbi = getMasterChiefAbiAsAny(masterChefAdr)

    const calls: Call[] = [getMasterChiefMethods(masterChefAdr).rewardName,
    getMasterChiefMethods(masterChefAdr).rewardPerBlock,
        'totalAllocPoint'].map((method) => {
            return {
                address: masterChefAdr,
                name: method,
            }
        })

    calls.push({
        address: masterChefAdr,
        name: 'poolInfo',
        params: [pid]
    })

    const multiResult = await multicallv2(masterChefAbi, calls)

    const [[rewardAdr], [rewardPerBlock], [totalAllocPoint], poolInfo] = multiResult

    const allocPoint = poolInfo[1]
    
    const rTotalReward = new BigNumber(rewardPerBlock.toString())
    const rTotalAlloc =  parseInt(totalAllocPoint.toString())
    const rPoolAlloc = parseInt(allocPoint.toString())

    return {
        rewardAdr: rewardAdr,
        totalRewardPerSec: rTotalReward,
        totalAllocPoint: rTotalAlloc,
        poolAllocPoint: rPoolAlloc,
    }
}

export default fetchPoolInfoMasterChef