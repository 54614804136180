import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { fetchDepositedOnLTS } from "../functions/userData/fetchDepositedOnLTS";
import { TokenBalanceData } from "./useTokenBalance";


export const useDepositedOnLTS = (ltsAdr: string, account: string | null | undefined): TokenBalanceData => {

  
    const [deposited, setDeposited] = useState<BigNumber>(new BigNumber(0));

    const [manualRefresh, setManualRefresh] = useState<boolean>(true)

    const callStakedRefresh = () => {
        setManualRefresh((prev) => !prev)
    }


    useEffect(() => {
        const fetchDeposited = async () => {
            const bal = await fetchDepositedOnLTS(ltsAdr, account as string)

            setDeposited(bal)
        }

        if (typeof account === 'string')
            fetchDeposited()

    }, [ltsAdr, account, manualRefresh])

    return {balance: deposited, refresher : callStakedRefresh}
};

export default useDepositedOnLTS;