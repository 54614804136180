import BigNumber from "bignumber.js"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { callRouterAdd } from "../../functions/userActions/callRouterAdd"
import { simpleRPCProvider } from "../../utils/simpleRPCProvider"
import { useSpecificRouter } from "../useContract"


export const useRouterAdd = (routerAdr: string, token0Adr: string, token1Adr: string) => {

    const routerContract = useSpecificRouter(routerAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleAdd = useCallback(
        async (amount0: BigNumber, amount1: BigNumber) => {
            const blockNumber = await simpleRPCProvider.getBlockNumber()
            const blockTimeStamp = (await simpleRPCProvider.getBlock(blockNumber)).timestamp

            const txhash = await callRouterAdd(routerContract, token0Adr, token1Adr, amount0, amount1, blockTimeStamp+2000)
            console.info('TRX result is : '  + txhash)

            if(txhash.toString() === '1'){
                enqueueSnackbar('LP tokens created!', {variant:'success'})
            }

        }, [routerContract, token0Adr, token1Adr, enqueueSnackbar]
    )

    return { onAdd: handleAdd }
}