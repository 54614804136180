import BigNumber from "bignumber.js";
import getBaseTokenPriceInUSD from "./getBaseTokenPriceInUSD";
import getLPTokenRatio from "./getLPTokenRatio";
import getTokenValueInBase from "./getTokenValueInBase";


export const getLPTokenValueInUSD = async (lpAdr: string, token0Adr: string, token1Adr: string, token0Deci: number, token1Deci:number, factoryAdr? : string | undefined): Promise<BigNumber> => {

    const [{ ratioToken0, ratioToken1 }, token0PriceInBase, token1PriceInBase, FTMPrice] = await Promise.all([
        getLPTokenRatio(lpAdr),
        getTokenValueInBase(token0Adr, factoryAdr ),
        getTokenValueInBase(token1Adr, factoryAdr),
        getBaseTokenPriceInUSD()
    ])

    const lpToken0Value = ratioToken0.multipliedBy(token0PriceInBase).multipliedBy(FTMPrice)
    const lpToken1Value = ratioToken1.multipliedBy(token1PriceInBase).multipliedBy(FTMPrice)

    const globalLPPrice = lpToken0Value.plus(lpToken1Value)

    // console.log('Global Price : ' + globalLPPrice)

    return globalLPPrice
}

export default getLPTokenValueInUSD