import { Theme } from "@mui/material"

export const styleMaterialCard = {
    borderRadius: 8,

    boxShadow: '5px 5px 6px 1px #dddddd, -5px -5px 6px 1px white',
}

export const styleBaseButton = {
    width: '12ch',
    border: '3px solid',
    borderRadius: 8,
    fontSize: 22,

    '&:hover': {
        border: '3px solid',
    }
}

export const styleMenuButtonBase = {
    mx: 1,
    width: '12ch',
    borderRadius: 8,
    padding: '0px',
    color: (theme: Theme) => theme.palette.primary.dark,
    '&:hover': {
        border: '1px solid',
        color: (theme: Theme) => theme.palette.primary.light,
      
    }
}

export const styleMenuButtonActivated = {
    mx: 1,
    width: '12ch',
    border: '0px solid',
    borderRadius: 8,

    padding: '0px',
    color: (theme: Theme) => theme.palette.secondary.main,
    '&:hover': {
        border: '1px solid',
       
    }
}

export const styleClaimSmallButton = {
    width: '12ch',
    padding: '2px 10px 2px 10px',
    border: '3px solid',
    borderRadius: 8,
    fontSize: 18,


    '&:hover': {
        border: '3px solid',
    }
}

export const styleMaxButton = {
    border: '0px solid',
    padding: '0px',
    width: '5ch',
    minWidth: '0ch',

    '&:hover': {
        backgroundColor: '#eeeeee',
    }
}

export const styleShadowedTextFieldGlobal = {
    mx: 1,

    '& .MuiInputBase-root': {
        p: 0,
        backgroundColor: '#eeeeee',
        borderRadius: 2,
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            boxShadow: ' -1px -2px 1px 1px #cccccc,  1px 2px 2px 1px white',
            borderWidth: 0,
            borderRadius: 2,

        },
        '&.Mui-focused fieldset': {
            borderColor: (theme: Theme) => theme.palette.secondary.main,
        },
    },
}

export const styleShadowedTextFieldGlobalError = {
    mx: 1,

    '& .MuiInputBase-root': {
        p: 0,
        backgroundColor: '#eeeeee',
        borderRadius: 2,
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            boxShadow: ' -1px -2px 1px 1px #cccccc,  1px 2px 2px 1px white',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: (theme: Theme) => theme.palette.error.main,

            '&hover': {
                borderColor: (theme: Theme) => theme.palette.secondary.main,
            },
        },


    },
}



