
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { fetchWithdrawable } from "../functions/userData/fetchWithdrawable";
import useTimedRefresh from "./useTimedRefresh";
import { TokenBalanceData } from "./useTokenBalance";


export const useWithdrawableReward = (gVaultAdr: string, iVaultAdr: string, account: string | null | undefined): TokenBalanceData => {

    const {timer} = useTimedRefresh();


    const [amount, setAmount] = useState<BigNumber>(new BigNumber(0));


    const [manualRefresh, setManualRefresh] = useState<boolean>(true)

    const callAmountRefresh = () => {
        setManualRefresh((prev) => !prev)
    }

    useEffect(() => {
        const fetchAmount = async () => {
            const bal = await fetchWithdrawable(gVaultAdr, iVaultAdr, account as string)

            setAmount(bal)
        }

        if (typeof account !== 'string') {
            setAmount(new BigNumber(0))
            return
        }
        else
        {
            fetchAmount();
        }


    }, [gVaultAdr, iVaultAdr, account, timer, manualRefresh])


    return {balance : amount, refresher: callAmountRefresh}
}