import { appDefaultChainID, ftmDefaultRPCURL, ftmDefaultScanURL } from "../values/defaultValues"
declare var window: any

export const setupNetwork = async () => {
    const provider = window.ethereum
    if (provider) {
        const chainId = parseInt(appDefaultChainID.toString(), 10)
        try {
            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: `0x${chainId.toString(16)}`,
                        chainName: 'Fantom Opera',
                        nativeCurrency: {
                            name: 'FTM',
                            symbol: 'FTM',
                            decimals: 18,
                        },
                        rpcUrls: [ftmDefaultRPCURL],
                        blockExplorerUrls: [`${ftmDefaultScanURL}/`],
                    },
                ],
            })
            return true
        } catch (error) {
            console.error('Failed to setup the network in Metamask:', error)
            return false
        }
    } else {
        console.error("Can't setup the FTM network on metamask because window.ethereum is undefined")
        return false
    }
}