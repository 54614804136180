import { Theme } from "@mui/material"

export const styleStratCardGlobalBox = {
    borderRadius: 8,

    boxShadow: '5px 5px 6px 1px #dddddd, -5px -5px 6px 1px white',

    marginTop: '24px',

}

export const styleCardLiqAddFieldBox = {
    display: 'flex',
    justifyContent: 'space-around',
    p: 1,
}


export const styleCardLiqSlider = {
    mx:{xs:4, sm:2},

    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#dddddd',
        height: 2,
        boxShadow: ' -0px -1px 1px 1px #cccccc,  1px 1px 1px 1px white'
    },
    '& .MuiSlider-track': {
        height: 2,
    }
}

export const styleCardLiqBorder = {
    border: '1px solid',
    borderRadius: 4,
    borderColor: '#dddddd',
    boxShadow: 'inset 0px 1px 1px 1px white, 0px 1px 1px 1px white'
}


export const styleCardLiqButtonSelect = {
    borderRadius: 8,
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    color: 'white',
    px: 1,
    py: 0,
    mx: 1,

    '&:hover': {
        backgroundColor: (theme: Theme) => theme.palette.primary.main,
    }
}

export const styleCardLiqButtonUnselect = {
    borderRadius: 8,
    borderColor: (theme: Theme) => theme.palette.primary.main,
    border: '1px solid',
    px: 1,
    py: 0,
    mx: 1,
}


export const styleCardTab = {


    "&.MuiTab-root": {
        minHeight: '32px',
        color: (theme: Theme) => theme.palette.neutral.dark,
    },


    "&.MuiButtonBase-root": {

    },

    "&.Mui-selected": {
        color: (theme: Theme) => theme.palette.primary.main,
    }

}