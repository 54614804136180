import { useEffect, useState } from "react";
import { fetchAllLTSStatic } from "../functions/lpToStables/fetchAllLTSStatic";
import { LTSStaticData } from "../types/ltsTypes";

//Objectif : un hook, qui return une liste d'objets FARM, avec leur valeurs de bases (LIQUIDITY, RATIO, ETC)

//Faire un deuxième hook pour permet de filtrer en fonction de si on a stake ou pas :o

export const useLTSStaticList = (): LTSStaticData[] => {

    const [ltsList, setLTSList] = useState<LTSStaticData[]>([])

    useEffect(() => {

        const getLTS = async () => {
            console.log('Static List Fetching')
            const allLTS = await fetchAllLTSStatic()
            setLTSList(allLTS)
        }

        getLTS()

    }, [])

    return ltsList

};

export default useLTSStaticList;