import { getGasEstimateAndRun } from "../getGasEstimate";

export const callGiverVaultGFarm = async (giverVaultContract: any) => {

    const returnValue = await getGasEstimateAndRun(giverVaultContract, "gFarmRoutine", [], 1100 )

    return returnValue
}

export const callGiverVaultClaim = async (giverVaultContract: any) => {

    const returnValue = await getGasEstimateAndRun(giverVaultContract, "harvest", [], 1100 )

    return returnValue
}

export const callGiverVaultMCDDeposit= async (giverVaultContract: any) => {

    const returnValue = await getGasEstimateAndRun(giverVaultContract, "depositToMC", [], 1100 )

    return returnValue
}

export const callIndexedVaultCompoundRoutine = async (indexedVaultContract: any) => {
    const returnValue = await getGasEstimateAndRun(indexedVaultContract, "compoundRoutine", [], 1100 )

    return returnValue
}

export const callGiverVaultCompoundIVault = async (giverVaultContract : any) => {
    const returnValue = await getGasEstimateAndRun(giverVaultContract, "iCompoundRoutine", [], 1100)

    return returnValue
}

export const callGiverVaultRewardAction = async (giverVaultContract : any) => {
    const returnValue = await getGasEstimateAndRun(giverVaultContract, "rewardAction", [], 1100)

    return returnValue
}