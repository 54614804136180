import { Box } from "@mui/system";
import { styleMaterialCard } from "./styled";


export const MaterialCard = ({ children, sx }: any) => {
    return (
        <Box sx={{...styleMaterialCard, ...sx}}>{children}</Box>
    )
};

export default MaterialCard;