import BigNumber from "bignumber.js";
import { getGasEstimateAndRun } from "../getGasEstimate";



export const callGiverVaultWithdraw = async (giverVaultContract: any, amount: BigNumber) => {


  const value = amount.toString()

  console.log('Value : ' + value)

  const returnValue = await getGasEstimateAndRun(giverVaultContract, "withdraw(uint256)", [value], 1100 )

  return returnValue
}