import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import StrategyCard from "./StrategyCard/StrategyCard";
import { SwitchFilter } from "./SwitchFilter/SwitchFilter";
import { keyframes } from '@mui/system';
import { PageFooter } from "./PageFooter/PageFooter";
import { LTSData, LTSStaticData } from "../types/ltsTypes";
import useLTSStaticList from "../hooks/useLTSStaticList";
import useLTSDynamicList from "../hooks/useLTSDynamicList";
import { useFilterByDeposit } from "../hooks/useFilterByDeposit";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { BountyCard } from "./BountyCard/BountyCard";


const wave = keyframes`
from{
    transform: translate(0px, 0%);
}
to {
    transform: translate(0px, 6%);
}
`;


export type CardsDisplayProps = {
    menuState: number
}



export const CardsDisplay: React.FC<CardsDisplayProps> = ({ menuState }) => {


    const [filter, setFilter] = useState(false)

    const { account } = useActiveWeb3React()

    const staticList: LTSStaticData[] = useLTSStaticList()

    const dynamicList: LTSData[] = useLTSDynamicList(staticList)

    const toDisplay: LTSData[] = useFilterByDeposit(dynamicList, filter, account)

    //Utiliser la liste pour la page bounty aussi :)  

    const typoText = menuState === 0 ? 'STAKE LIQUIDITY EARN STABLE!' : 'COLLECT BOUNTIES EARN RADFI!'


    return (
        <Box mt={4} pt={8}>

            <Container maxWidth='lg' >
                <Box sx={{ display: { sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ lineHeight: 0.8, fontSize: { xs: 36, sm: 46, md: 64 } }} variant="h2">{typoText}

                        </Typography>
                        <Box component='img'
                            sx={{
                                position: 'relative',
                                width: { xs: '150px', md: '225px', xl: '299px' },
                                height: { xs: '100px', md: '150px', xl: '199px' }, top: '-20px',
                                left: { xs: '-70px', md: '-75px', xl: '-100px' },

                                animation: `${wave} 2s infinite ease alternate`,
                                mb: -3
                            }} src='/img/Logo_RDPRT.png'></Box>
                    </Box>

                    {menuState === 0 && <Box sx={{ display: 'inline', minWidth: '18ch' }}>

                        <SwitchFilter filter={filter} setFilter={setFilter} />
                    </Box>}

                </Box>

                {(menuState === 0) ? (

                    toDisplay.map((ltsData) => {
                        return (
                            <StrategyCard
                                key={ltsData.gMasterChefPid}
                                ltsData={ltsData} />
                        )
                    }
                    )) :
                    (

                        dynamicList.map((ltsData) => {
                            return (
                                <BountyCard
                                    key={ltsData.gMasterChefPid}
                                    ltsData={ltsData} />
                            )
                        }
                        ))
                }

            </Container>

            <PageFooter />
        </Box>
    )
}

export default CardsDisplay;