import { LTSData, LTSDynamicData, LTSStaticData } from "../../types/ltsTypes";


export const combineStaticAndDynamic = (staticData: LTSStaticData, dynamicData: LTSDynamicData): LTSData => {

    return {

        gVaultAdr: staticData.gVaultAdr,
        iVaultAdr: staticData.iVaultAdr,

        gMasterChefAdr: staticData.gMasterChefAdr,
        gRouterAdr: staticData.gRouterAdr,

        iMasterChefAdr: staticData.iMasterChefAdr,
        iRouterAdr: staticData.iRouterAdr,

        gWantAdr: staticData.gWantAdr,
        gWantReserve0: dynamicData.gWantReserve0,
        gWantReserve1: dynamicData.gWantReserve1,
        gWantTotalSupply: dynamicData.gWantTotalSupply,
        gWantTokenPrice: dynamicData.gWantTokenPrice,
        iWantAdr: staticData.iWantAdr,
        iWanttokenPrice: dynamicData.iWanttokenPrice,

        //gRewardAdr: string,

        gToken0Infos: staticData.gToken0Infos,
        gToken1Infos: staticData.gToken1Infos,
        iToken0Infos: staticData.iToken0Infos,
        iToken1Infos: staticData.iToken1Infos,

        gEarnAdr: staticData.gEarnAdr,
        gMasterChefPid: staticData.gMasterChefPid,

        iEarnAdr: staticData.iEarnAdr,
        iMasterChefPid: staticData.iMasterChefPid,

        gVaultTotalShares: dynamicData.gVaultTotalShares,

        gMCRewardPerSec: staticData.gMCRewardPerSec,
        gMCAllocPoint: staticData.gMCAllocPoint,
        gMCTotalAlloc: staticData.gMCTotalAlloc,
        gEarnTokenPrice: dynamicData.gEarnTokenPrice,
        gMCEarnTotalShares: dynamicData.gMCEarnTotalShares,

        owner: staticData.owner,

        radFiMinter: staticData.radFiMinter
    }

}