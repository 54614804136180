import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { callGiverVaultClaim, callGiverVaultCompoundIVault, callGiverVaultGFarm, callGiverVaultMCDDeposit, callGiverVaultRewardAction, callIndexedVaultCompoundRoutine } from "../../functions/userActions/callGiverVaultActions";
import { useSpecificGiverVault, useSpecificIndexedVault } from "../useContract";


export const useGiverVaultClaim = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleClaim = useCallback(
        async () => {
            const txHash = await callGiverVaultClaim(giverVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('Rewards claimed!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onClaim: handleClaim }
};


export const useGiverVaultGFarm = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleGFarm = useCallback(
        async () => {
            const txHash = await callGiverVaultGFarm(giverVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('G Farm OK!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onGFarm: handleGFarm }
};

export const useGiverVaultMCDeposit = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleMCDeposit = useCallback(
        async () => {
            const txHash = await callGiverVaultMCDDeposit(giverVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('MC Deposit OK!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onMCDeposit: handleMCDeposit }
};

export const useIndexedVaultCompoundRoutine = (indexedVaultAdr: string) => {

    const indexedVault = useSpecificIndexedVault(indexedVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleComRoutine = useCallback(
        async () => {
            const txHash = await callIndexedVaultCompoundRoutine(indexedVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('CompoundRoutine OK!', {variant:'success'})
            }
        },
        [indexedVault, enqueueSnackbar],
    )

    return { onComRoutine: handleComRoutine }
};

export const useGiverVaultCompound = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleGiverCompound = useCallback(
        async () => {
            const txHash = await callGiverVaultCompoundIVault(giverVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('Compounding OK!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onGiverCompound: handleGiverCompound }
};

export const useGiverVaultRewardAction = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleGiverReward = useCallback(
        async () => {
            const txHash = await callGiverVaultRewardAction(giverVault)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('Action reward OK!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onGiverReward: handleGiverReward }
};