import BigNumber from "bignumber.js";
import { getPairContract } from "../utils/getContract";

export type LPTokenRatio = {
    ratioToken0: BigNumber,
    ratioToken1: BigNumber,
}

const getLPTokenRatio = async (lpAdr: string): Promise<LPTokenRatio> => {

    const pairContract = getPairContract(lpAdr)

    const [{ _reserve0, _reserve1 }, totalSupply] = await Promise.all([
        pairContract.getReserves(),
        pairContract.totalSupply()
    ])

    const ratio0 = (new BigNumber(_reserve0.toString())).div(new BigNumber(totalSupply.toString()))
    const ratio1 = (new BigNumber(_reserve1.toString())).div(new BigNumber(totalSupply.toString()))

    return {
        ratioToken0: ratio0,
        ratioToken1: ratio1,
    }
}

export default getLPTokenRatio