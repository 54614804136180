import BigNumber from "bignumber.js"
import { getSpookyFactoryAdr } from "../utils/getAddress"
import getBaseTokenPriceInUSD from "./getBaseTokenPriceInUSD"
import getTokenValueInBase from "./getTokenValueInBase"




export const getTokenValueInUSD = async (tokenAdr: string, factoryAdr?: string): Promise<BigNumber> => {

    const currentFactoryAdr = factoryAdr ?? getSpookyFactoryAdr()

    const tokenValueInBase = await getTokenValueInBase(tokenAdr, currentFactoryAdr)

    const basePriceInUSD = await getBaseTokenPriceInUSD()

    const tokenValueInBusd = tokenValueInBase.times(basePriceInUSD)

    return tokenValueInBusd
}