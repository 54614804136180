import BigNumber from "bignumber.js"
import { getSpecificMasterChefContract } from "../utils/getContract"
import { getMasterChiefMethods } from "../values/getMasterChefMethods"


export const getPendingReward = async (mCAdr: string, mCPid:number, accountAdr: string ) => {
    const mCContract = getSpecificMasterChefContract(mCAdr)

    const pendingReward = await mCContract[getMasterChiefMethods(mCAdr).pendingReward](mCPid, accountAdr)

    return new BigNumber(pendingReward.toString())
}