import { ContractInterface } from "@ethersproject/contracts"

import spookyChefAbi from "../values/abi/abi_spookyChef.json";
import spiritChefAbi from "../values/abi/abi_spiritChef.json"



export type MCMethods = {
    rewardName: string,
    rewardPerBlock: string,
    pendingReward: string,
}

export const getMasterChiefAbi = (mCAdr: string): ContractInterface => {
    switch (mCAdr) {
        case "0x2b2929E785374c651a81A63878Ab22742656DcDd":
            return spookyChefAbi
        case "0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093":
            return spiritChefAbi
        default:
            return spookyChefAbi
    }
}

export const getMasterChiefAbiAsAny = (mCAdr: string) => {
    switch (mCAdr) {
        case "0x2b2929E785374c651a81A63878Ab22742656DcDd":
            return spookyChefAbi
        case "0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093":
            return spiritChefAbi
        default:
            return spookyChefAbi
    }
}

export const getMasterChiefMethods = (mCAdr: string): MCMethods => {

    switch (mCAdr) {
        case "0x2b2929E785374c651a81A63878Ab22742656DcDd":
            return {
                rewardName: 'boo',
                rewardPerBlock: 'booPerSecond',
                pendingReward: 'pendingBOO',
            }
        case "0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093":
            return {
                rewardName: 'spirit',
                rewardPerBlock: 'spiritPerBlock',
                pendingReward: 'pendingSpirit'
            }

        default:
            return {
                rewardName: 'boo',
                rewardPerBlock: 'booPerSecond',
                pendingReward: 'pendingBOO',
            }
    }
}

export const getMasterChiefName = (mCAdr: string): string => {

    switch (mCAdr) {
        case "0x2b2929E785374c651a81A63878Ab22742656DcDd":
            return 'SpookySwap'
        case "0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093":
            return 'SpiritSwap'
        default: return 'SpookySwap'
    }

}

export const getRouterPermitName = (routerAdr: string): string => {
    switch (routerAdr) {
        case "0xF491e7B69E4244ad4002BC14e878a34207E38c29":
            return 'Spooky LP'
        case "0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52":
            return 'Spirit LPs'
        default:
            return 'Spooky LP'
    }
}