import { HelpOutlineRounded } from "@mui/icons-material"
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import BigNumber from "bignumber.js"
import { useState } from "react"
import { formatNumberTo2DeciFloor, formatNumberToXDeciFloor } from "../../utils/formatNumber"
import { RadFiPromiseButton } from "../StyledComponent/Buttons"


type DialogFarmProps = {
    open: boolean,
    handleClose: () => void,
    promiseAction: () => Promise<void>,
    promisePremiumAction: () => Promise<void>,
    amount: BigNumber,
    pendingHarvest: BigNumber, 
}


export const DialogFarmDeposit: React.FC<DialogFarmProps> = (props) => {

    const [premiumActivated, setPremiumActivated] = useState<boolean>(false)

    const handleCheckBoxChange = (event:any) => {
        setPremiumActivated(event.target.value)
    }

    const handlePromise = async () => {

        await props.promiseAction()

        props.handleClose()

    }

    const handlePremiumPromise = async () => {
        await props.promiseAction()

        props.handleClose()
    }

    return (
        <Dialog
            open={props.open}>
            <DialogTitle>DEPOSIT</DialogTitle>
            <DialogContent dividers>

                <Typography variant='body2'>LP to deposit:</Typography>
                <Typography variant='body1'>{formatNumberTo2DeciFloor(props.amount)} FTM-USDC</Typography>
                <Typography variant='body2'>reward harvesed: </Typography>
                <Typography variant='body1'>{formatNumberToXDeciFloor(props.pendingHarvest,4)} USDC sent to wallet! </Typography>

                <FormControlLabel control={<Checkbox value={premiumActivated} onChange={handleCheckBoxChange}/>} label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body2'>Premium Deposit?</Typography>
                        <Tooltip title={'High transaction fees but faster rewards'} arrow>
                            <HelpOutlineRounded sx={{ml:0.5, height: 20, width: 20 }} color='secondary' />
                        </Tooltip>
                    </Box>} />

            </DialogContent>
            <DialogActions>
                <RadFiPromiseButton label="Deposit" promiseAction={premiumActivated ? handlePremiumPromise : handlePromise} />

                <RadFiPromiseButton label="Cancel" promiseAction={props.handleClose} />
            </DialogActions>


        </Dialog>

    )
}