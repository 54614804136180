import BigNumber from "bignumber.js";
import { getERC20Contract } from "../utils/getContract";



const getBalance = async (tokenAdr: string, account: string) : Promise<BigNumber> => {
    const erc20Contract = getERC20Contract(tokenAdr)

    const balance = await erc20Contract.balanceOf(account)

    return new BigNumber(balance.toString())
}

export default getBalance