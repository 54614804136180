import BigNumber from "bignumber.js";

// * 100 floor, div 100
export const formatNumberTo2DeciFloor = (num: number | BigNumber) : string => {

    if (typeof num === "number")
    {
        const floored = Math.floor(num * 100)/100
        return floored.toString()
    }

    if( num.lt(0.01) && num.gt(0))
        return "0.00..."

    return num.toFixed(2,1).toString()
}

export const formatNumberToXDeciFloor = (num: number | BigNumber, deci: number) : string => {

    if (typeof num === "number")
    {
        const floored = Math.floor(num * (10**deci))/(10**deci)
        return floored.toString()
    }


    return num.toFixed(deci, 1).toString()
}