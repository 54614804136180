import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { simpleRPCProvider } from "../utils/simpleRPCProvider";



const getBaseBalance = async (account: string, signer?:  ethers.Signer | ethers.providers.Provider) : Promise<BigNumber> => {
    const toUseSigner = signer ?? simpleRPCProvider

    const balance = await toUseSigner.getBalance(account)

    return new BigNumber(balance.toString())
}

export default getBaseBalance