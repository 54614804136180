import { useCallback, useState } from "react"


export const usePromiseActionResolve = (promiseAction: any) => {

    const [loading, setLoading] = useState<boolean>(false)

    const handleAction = useCallback(
        async () => {
            setLoading(true)
            
            try {
                await Promise.resolve(promiseAction())
            } catch {
                console.log('Error processing action, cancelling ...')
            }

            setLoading(false)

        }, [promiseAction]
    )

    return {handleAction: handleAction, loading: loading}


}