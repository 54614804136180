

export const imageFormatType = (tokenAdr: string) => {

    switch(tokenAdr){
        case "0x5Cc61A78F164885776AA610fb0FE1257df78E59B":
            return 'png'

        default:
            return 'svg'
    }
}