
//Recup
//IWant
//IMasterChefAdr
//iMasterChefPid
//iRouter
//currentRCounter

import BigNumber from "bignumber.js"
import { multicallv2 } from "../../utils/multicall"
import indexedVaultAbi from "../../values/abi/abi_indexedVault.json"

export type IndexedVaultData = {
    iWantAdr : string,
    iMasterChefAdr: string,
    iMasterChefPid: number,
    iEarn: string,
    iRouterAdr: string,
    currentRCounter: number,
   // rIndex: BigNumber
}

export const fetchIndexedVault = async (indexedVaultAdr: string) : Promise<IndexedVaultData> => {

    const calls = ['iWant', 'iMasterChef', 'iMCPid','iEarn', 'iRouter','getCurrentRCounter', ].map((method) => {
        return {
            address: indexedVaultAdr,
            name: method
        }
    })

    const [[iWant],[iMasterChef], [iMCPid], [iE], [iRouter], [curRIndex],  ] = await multicallv2(indexedVaultAbi, calls)

  
    const parsePID = parseInt(iMCPid)
    const parsecurRIndex = parseInt(curRIndex)

    //const bNRIndex = new BigNumber(rI.toString())

    return ( {
        iWantAdr : iWant,
        iMasterChefAdr: iMasterChef,
        iMasterChefPid: parsePID,
        iEarn: iE,
        iRouterAdr: iRouter,
        currentRCounter: parsecurRIndex,
        //rIndex: bNRIndex
    })



}