import { Avatar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import BigNumber from "bignumber.js"
import React from "react"
import { formatNumberToXDeciFloor } from "../../utils/formatNumber"
import { getTokenImage } from "../../utils/getTokenImage"


export type TokenIconBalanceProps = {
    tokenAdr: string,
    qty: BigNumber
}


export const TokenIconBalance: React.FC<TokenIconBalanceProps> = ({tokenAdr, qty}) => {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar sx={{ width: 36, height: 36, mr: 1 }} src={getTokenImage(tokenAdr, false)}></Avatar>
            <Typography>{formatNumberToXDeciFloor(qty, 4)}</Typography>
        </Box>
    )
}