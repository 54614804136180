import { FormControl, FormHelperText, InputAdornment, OutlinedInput, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { styleShadowedTextFieldGlobal, styleShadowedTextFieldGlobalError } from "./styled"



export const ShadowedTextField = (props: any) => {

    const { label, value, onChange, symbol, isError,} = props

    return (
        <Box sx={isError ? styleShadowedTextFieldGlobalError : styleShadowedTextFieldGlobal}>
            <FormControl variant="standard">
                <OutlinedInput
                   id="component-simple"
                    type="number"
                    value={value}
                    onChange={onChange}
                    startAdornment={<InputAdornment position='end'>
                        <Typography sx={{ mr: 1 }} variant='body2'>{symbol}</Typography>
                    </InputAdornment>} />
                <FormHelperText sx={{ display: 'flex', justifyContent: 'flex-end' }}>{label}</FormHelperText>
            </FormControl>
        </Box >

    )

}
// <TextField sx={styleShadowedTextField} {...other}/>