import { LTSStaticData } from "../../types/ltsTypes";

import fetchLiquidityPool from "../strategies/fetchLiquidityPool";
import fetchPoolInfoMasterChef from "../strategies/fetchPoolInfoMasterChef";
import fetchToken from "../strategies/fetchToken";
import { fetchGiverVault } from "./fetchGiverVault";
import { fetchIndexedVault } from "./fetchIndexedVault";

export const fetchLTSStaticData = async (ltsAdr: string): Promise<LTSStaticData> => {

    const giverData = await fetchGiverVault(ltsAdr)


    const [indexedData, gWantInfos, mCInfo, ] = await Promise.all([
        fetchIndexedVault(giverData.iVaultAdr),
        fetchLiquidityPool(giverData.gWantAdr),
        fetchPoolInfoMasterChef(giverData.gMasterChefAdr, giverData.gMasterChefPid),
    ])

    const [iWantInfos] = await Promise.all([
        fetchLiquidityPool(indexedData.iWantAdr)
    ])

    const [token0Info, token1Info, iToken0Info, iToken1Info] = await Promise.all([
        fetchToken(gWantInfos.token0Adr),
        fetchToken(gWantInfos.token1Adr),
        fetchToken(iWantInfos.token0Adr),
        fetchToken(iWantInfos.token1Adr),
    ])

    return ({
        gVaultAdr: ltsAdr,
        iVaultAdr: giverData.iVaultAdr,
        gMasterChefAdr: giverData.gMasterChefAdr,
        gRouterAdr: giverData.gRouterAdr,
        iMasterChefAdr: indexedData.iMasterChefAdr,
        iRouterAdr: indexedData.iRouterAdr,

        gWantAdr: giverData.gWantAdr,
  
        iWantAdr: indexedData.iWantAdr,
  
        gToken0Infos: token0Info,
        gToken1Infos: token1Info,
         
        iToken0Infos: iToken0Info,
        iToken1Infos: iToken1Info,

        gEarnAdr: giverData.gEarn,
        gMasterChefPid: giverData.gMasterChefPid,
        iEarnAdr: indexedData.iEarn,
        iMasterChefPid: indexedData.iMasterChefPid,
    
        gMCRewardPerSec: mCInfo.totalRewardPerSec,
        gMCAllocPoint: mCInfo.poolAllocPoint,
        gMCTotalAlloc: mCInfo.totalAllocPoint,

        owner: giverData.owner,
        radFiMinter: giverData.radFiMinter
        
    })
}