import { useEffect, useState } from "react";
import { fetchAllLTSDynamic } from "../functions/lpToStables/fetchAllLTSDynamic";
import { FetchingError } from "../types/errorEnum";
import { LTSData, LTSStaticData } from "../types/ltsTypes";
import useTimedRefresh from "./useTimedRefresh";


export const useLTSDynamicList = (staticList: LTSStaticData[]): LTSData[] => {

    const [ltsList, setLTSList] = useState<LTSData[]>([])

    //Launch a component refresh
    const {timer} = useTimedRefresh()

    //Launch a second component refresh
    useEffect(() => {

        const getLTS = async () => {
            console.log('Dynamic List Fetching')
            const [allLTS, fetchingResult] = await fetchAllLTSDynamic(staticList)

            if ( fetchingResult === FetchingError.SUCCESS)
            {
                setLTSList(allLTS)
                console.log(allLTS)
            }
        }

        getLTS()

    }, [staticList, timer])

    return ltsList

};

export default useLTSDynamicList;