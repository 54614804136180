import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { callTokenApprove } from "../../functions/userActions/callTokenApprove"
import { useTokenContract } from "../useContract"

export const useTokenApprove = (tokenAdr: string, spender: string) => {

    const tokenContract = useTokenContract(tokenAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleApprove = useCallback(
        async () => {
            const tx = await callTokenApprove(tokenContract, spender)

            console.info('TRX result is : '  + tx)

            if(tx.toString() === '1'){
                enqueueSnackbar('Successfully approved token', {variant:'success'})
            }
        },
        [tokenContract, spender, enqueueSnackbar]
    )

    return {onApprove : handleApprove}

}