import BigNumber from "bignumber.js";
import { Contract, PayableOverrides } from "ethers";
import { getGasPrice } from "./getGasPrice";


export const getGasEstimate = async (contract: Contract, methodName: string, args: any[] = [], marginPer1000: number = 1100, overrides: PayableOverrides = {}) => {

    if (!contract[methodName]) {
        throw new Error(`Method ${methodName} doesn't exist on ${contract.address}`)
    }

    const gasPrice = await contract.estimateGas[methodName](...args, { ...overrides })
    const bNgasPrice = new BigNumber(gasPrice.toString())
    const marginGasPrice = bNgasPrice.times(marginPer1000).div(1000).toFixed(0)
    return marginGasPrice

}

export const getGasEstimateAndRun = async (contract: Contract, methodName: string, args: any[] = [], marginPer1000: number = 1100, overrides: PayableOverrides = {}) => {
    if (!contract[methodName]) {
        throw new Error(`Method ${methodName} doesn't exist on ${contract.address}`)
    }

    const estimateGasNumber = getGasEstimate(contract, methodName, args, marginPer1000, overrides)

    const gasPrice = (await getGasPrice()).toString()

    const tx = await contract[methodName](...args, { gasLimit: estimateGasNumber, gasPrice: gasPrice, ...overrides })
    const receipt = await tx.wait()
    return receipt.status

}