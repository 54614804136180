import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { InjectedConnector } from '@web3-react/injected-connector'
import { useCallback } from "react"
import { setupNetwork } from "../utils/wallet"

const useAuth = () => {

    const {activate,} = useWeb3React()

    const login = useCallback( () => {

        const connector = new InjectedConnector({supportedChainIds: [250]})

        if (connector)
        {
            activate(connector, async (error:Error) => {
                //LEARN : Utilisation du instanceof au lieu de typeof
                if (error instanceof UnsupportedChainIdError){

                    const hasSetup = await setupNetwork()

                    if(hasSetup){
                        activate(connector)
                    }
                }
                else
                {
                    console.log('Other errors')
                }
            } )
        }
        else
        {
            console.log('USEAUTH HOOK : Cant find connector')
        }


    }, [activate])

    return {login}
}

export default useAuth