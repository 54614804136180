import { Button } from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { formatNumberTo2DeciFloor } from "../../../utils/formatNumber";
import { BIG_POW_X } from "../../../utils/utilsBigNumber";
import { ShadowedTextField } from "../../StyledComponent/ShadowedTextField";



export const TokenTextField = (props: any) => {

    const { maxValue, tokenDeci, valueSetter, forcedValue, label, ...other } = props

    const [readableValue, setReadableValue] = useState<string>('0.00')

    const handleChange = (newValue: string) => {
        setReadableValue(newValue)
        valueSetter(new BigNumber(newValue).times(BIG_POW_X(tokenDeci)))
    }

    const handleMax = () => {
        valueSetter(maxValue)
        setReadableValue(formatNumberTo2DeciFloor(maxValue.div(BIG_POW_X(tokenDeci))))
    }

    const isError = () => {
        if (forcedValue) {
            return (new BigNumber(forcedValue).gt(maxValue.div(BIG_POW_X(tokenDeci))))
        }
        else {
            return (new BigNumber(readableValue).gt(maxValue.div(BIG_POW_X(tokenDeci))))
        }


    }


    return (
        <Box display='flex' justifyContent='center' alignItems='flex-start'>
            <ShadowedTextField
                type='number'
                value={forcedValue ?? readableValue}
                onChange={(event: any) => handleChange(event.target.value)}
                isError={isError()}
                label={<Button sx={{ p: 0, color: 'black', textTransform: 'none', alignItems: 'center' }} size='small' onClick={handleMax}>{label}</Button>}
                {...other}
            />
        </Box>

    )
};

export default TokenTextField;