export const chainName = function (chainID: number | undefined): string {
    if (typeof chainID === undefined)
    {
        return 'chain not set'
    }

    switch (chainID) {
        case 56:
            return 'BSC'
        case 250:
            return 'FTM'
        default:
            return 'Not supported'
    }
}