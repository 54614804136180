import { ThemeProvider } from "@mui/material"
import { lightTheme } from "./mainTheme"


export const MainThemeProvider = ({ children }: any) => {

    return (
        <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
    )

}