import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { LTSData } from "../../types/ltsTypes";
import { TokenBalanceData } from "../../hooks/useTokenBalance";
import CardLiquidityAdd from "./CardLiquidityAdd";
import CardLiquidityRemove from "./CardLiquidityRemove";
import { styleCardLiqButtonSelect, styleCardLiqButtonUnselect } from "./style/styled";

export type CardLiquidityProps = {
    ltsData: LTSData,
    value: number,
    balance0 : TokenBalanceData,
    balance1 : TokenBalanceData,
    lpBalance: TokenBalanceData,
}
export const CardLiquidity: React.FC<CardLiquidityProps> = ({ ltsData, value, balance0, balance1, lpBalance}) => {

    const [buttonValue, setButtonValue] = useState<number>(0)


    const handleChange = (value: number) => {

        setButtonValue(value)

    }

    return (
        <Box hidden={value !== 1}>
            <Box  sx={{p:1}} display='flex' justifyContent='center'>
                <Button
                    onClick={() => handleChange(0)}
                    sx={buttonValue===0 ? styleCardLiqButtonSelect : styleCardLiqButtonUnselect}>ADD</Button>
                <Button
                    onClick={() => handleChange(1)}
                    sx={buttonValue===1 ? styleCardLiqButtonSelect : styleCardLiqButtonUnselect}>REMOVE</Button>
            </Box>

        <CardLiquidityAdd ltsData={ltsData} value={buttonValue} balance0={balance0} balance1={balance1} lpBalance={lpBalance} />
        <CardLiquidityRemove ltsData={ltsData} value={buttonValue} lpBalance={lpBalance} balance0={balance0} balance1={balance1}/>
        </Box>
    )
};

export default CardLiquidity;