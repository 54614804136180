import { ArticleRounded, IsoRounded, LocalFloristRounded } from "@mui/icons-material";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { LTSData } from "../../types/ltsTypes";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useDepositedOnLTS from "../../hooks/useDepositedOnLTS";
import useTokenBalance from "../../hooks/useTokenBalance";
import { useWithdrawableReward } from "../../hooks/useWithdrawableReward";
import CardConnect from "./CardConnect";
import CardFarm from "./CardFarm";
import CardLiquidity from "./CardLiquidity";
import CardSummary from "./CardSummary";
import { styleCardTab } from "./style/styled";



export type CardTabPanelProps = {
    ltsData: LTSData,
}


//Maybe list here the main hook ? (refreshers, balance, etc...)
export const CardTabPanel: React.FC<CardTabPanelProps> = ({ ltsData }) => {

    const [tabValue, setTabValue] = useState<number>(0)

    const { active, account } = useActiveWeb3React()

    const realTab = active ? tabValue : tabValue > 0 ? 3 : 0



    const balance0 = useTokenBalance(ltsData.gToken0Infos.adr, account)
    const balance1 = useTokenBalance(ltsData.gToken1Infos.adr, account)
    const lpBalance = useTokenBalance(ltsData.gWantAdr, account)

    //Faire un stacked on lts
    const stakedOnLTS = useDepositedOnLTS(ltsData.gVaultAdr, account)
    const withdrawableReward = useWithdrawableReward(ltsData.gVaultAdr, ltsData.iVaultAdr, account)



    const handleChange = (event: any, newTabValue: number) => {
        setTabValue(newTabValue)
    }

    return (
        <Box>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="fullWidth"
            >
                <Tab sx={styleCardTab} icon={<ArticleRounded />} iconPosition="start" label={<Typography variant='button'>Summary</Typography>} />
                <Tab sx={styleCardTab} icon={<IsoRounded />} iconPosition="start" label={<Typography variant='button'>Liquidity</Typography>} />
                <Tab sx={styleCardTab} icon={<LocalFloristRounded />} iconPosition="start" label={<Typography variant='button'>Farm</Typography>} />
            </Tabs>

            <CardSummary ltsData={ltsData} value={realTab} stakedOnManager={stakedOnLTS} withdrawableReward={withdrawableReward} />
            <CardLiquidity ltsData={ltsData} value={realTab} balance0={balance0} balance1={balance1} lpBalance={lpBalance} />
            <CardFarm ltsData={ltsData} value={realTab} stakedOnManager={stakedOnLTS} lpBalance={lpBalance} withdrawableReward={withdrawableReward}/>
            <CardConnect value={realTab} />
        </Box>

    )
};

export default CardTabPanel;