import { useContext } from "react"
import { TimedRefreshContext } from "../contexts/TimedRefreshContext"

export type TimerType = {
    timer: number,
    fastTimer: number,
}

const useTimedRefresh = (): TimerType => {

    const {refreshTimer, refreshFastTimer} = useContext(TimedRefreshContext)
    return {timer : refreshTimer, fastTimer: refreshFastTimer}
}

export default useTimedRefresh