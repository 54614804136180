import { LocalAtm } from "@mui/icons-material";
import { Button, CircularProgress, Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { usePromiseActionResolve } from "../../hooks/usePromiseActionResolve";
import { styleBaseButton, styleClaimSmallButton, styleMaxButton, styleMenuButtonActivated, styleMenuButtonBase, } from "./styled";


export const RadFiPromiseButton = (props: any) => {
    const { label, promiseAction, sx, size, ...other } = props

    const { handleAction, loading } = usePromiseActionResolve(promiseAction)

    const showTypoOrLoading = loading ? <CircularProgress size={30} variant='indeterminate' /> : <Typography variant='button'>{label}</Typography>

    return (
        <Box>
            <Button onClick={handleAction} sx={{ ...sx, ...styleBaseButton }} {...other}>
                {showTypoOrLoading}
            </Button>
        </Box>
    )
};

export const RadFiPromiseButtonSmall = (props: any) => {

    const { label, promiseAction, sx, ...other } = props

    const { handleAction, loading } = usePromiseActionResolve(promiseAction)

    const showTypoOrLoading = loading ? <CircularProgress size={30} variant='indeterminate' /> : <Typography variant='button'>{label}</Typography>

    return (
        <Box>
            <Button onClick={handleAction} sx={{ padding: '0px', ...styleBaseButton, ...sx }} size='small'{...other}>
                {showTypoOrLoading}
            </Button>
        </Box>
    )
};

export const OutLinedSmallClaimButton = (props: any) => {

    const { label, promiseAction, ...other } = props

    const { handleAction, loading } = usePromiseActionResolve(promiseAction)

    const showTypoOrLoading = loading ? <CircularProgress size={30} variant='indeterminate' /> : <Box sx={{ display: 'flex' }}><LocalAtm sx={{ mr: 1 }} /><Typography variant='button'>{label}</Typography></Box>

    return (
        <Box>
            <Button onClick={handleAction} sx={styleClaimSmallButton} size='small'{...other}>
                {showTypoOrLoading}
            </Button>
        </Box>
    )
};

export const MaxButton = (props: any) => {
    const { label, ...other } = props

    return (
        <Box>
            <Button sx={styleMaxButton} size='small'{...other}>
                <Typography sx={{ fontSize: 14, color: 'black' }} variant='button'>{label}</Typography>
            </Button>
        </Box>
    )

}


export const RadFiMenuButton = (props: any) => {
    const { label, buttonAction, activated, ...other } = props


    return (
        <Box>
            <Button onClick={buttonAction} sx={activated ? styleMenuButtonActivated : styleMenuButtonBase} size='small'>
                <Typography variant='body1' sx={{fontSize: activated ? 24 : 22}}>{label}</Typography>
            </Button>
        </Box>
    )

}

