import { DoubleArrowRounded } from "@mui/icons-material";
import { Avatar, AvatarGroup, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CalculateAPYLTS from "../../functions/calculateAPY_LTS";
import { calculateLiquidity } from "../../functions/calculateLiquidity";
import { LTSData } from "../../types/ltsTypes";
import { formatNumberTo2DeciFloor } from "../../utils/formatNumber";
import { getUSDCAdr } from "../../utils/getAddress";
import { getFixedApr } from "../../utils/getFixedApr";
import { getTokenImage } from "../../utils/getTokenImage";
import { LegendNumberImportant, LegendNumberInline } from "../StyledComponent/LegendNumber";


type CardLeftProps = {
    ltsData: LTSData,
}

export const CardLeft: React.FC<CardLeftProps> = ({ ltsData }) => {

    // const poolLiquidity = calculateLiquidity(stratData.mCPoolShares, stratData.lpTokenPrice)
    const giverVaultLiquidity = calculateLiquidity(ltsData.gVaultTotalShares, ltsData.gWantTokenPrice)

    return (
        <Box sx={{ display: 'flex', padding: '8px', height: '100%', alignItems: 'center' }}>

            <Grid container alignItems='center'>
                <Grid item xs={6} sm={12}>
                    <Box sx={{ height: '100%', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                            <AvatarGroup sx={{ justifyContent: 'center' }} >
                                <Avatar src={getTokenImage(ltsData.gToken0Infos.adr, false)}></Avatar>
                                <Avatar src={getTokenImage(ltsData.gToken1Infos.adr, false)} ></Avatar>
                            </AvatarGroup>
                            <DoubleArrowRounded color="secondary" />
                            <Avatar sx={{ml:'4px'}} src={getTokenImage(getUSDCAdr(), false)}></Avatar>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 24 }} noWrap>{ltsData.gToken0Infos.symbol + '-' + ltsData.gToken1Infos.symbol + ' to USDC'}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={12}>
                    <Box sx={{ display: 'block', height: '100%', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <LegendNumberImportant legend='APR:' number={formatNumberTo2DeciFloor(CalculateAPYLTS(ltsData, getFixedApr(ltsData.gWantAdr)).ultima) + '%'} />
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <LegendNumberInline legend='On strat:' number={'$ ' + formatNumberTo2DeciFloor(giverVaultLiquidity)} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" variant="middle" flexItem />

        </Box >
    )
};

export default CardLeft;