import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import getBalance from "../functions/getBalance";
import { getPendingReward } from "../functions/getPendingReward";
import useTimedRefresh from "./useTimedRefresh";


export const usePendingReward = (gMCAdr : string, gMCPid: number, checkAdr: string, tokenAdr: string) => {

    const {fastTimer} = useTimedRefresh()

    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));

    useEffect(() => {
        
        const fetchPending = async () => {

                const pendingReward = await getPendingReward(gMCAdr, gMCPid, checkAdr)
                const pendingBal = await getBalance(tokenAdr, checkAdr)

                setBalance(pendingReward.plus(pendingBal))
        }

        fetchPending()
    }, [fastTimer, gMCAdr, gMCPid, checkAdr])

    return balance
}