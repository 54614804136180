import BigNumber from "bignumber.js";
import { getPairContract } from "../../utils/getContract";
import { splitSignature } from '@ethersproject/bytes'
import { Web3Provider } from "@ethersproject/providers";
import { getRouterPermitName } from "../../values/getMasterChefMethods";

export type SignatureData = {
    v: number,
    r: string,
    s: string,
    deadline: number
}

export const callPermitForWithdraw = async (library: Web3Provider, account: string, routerAdr: string, pairAdr: string, liquidityAmount: BigNumber, deadline: number) => {

    var from = account

    var pairContract = getPairContract(pairAdr)
    var nonce = await pairContract.nonces(from)

    var msgParams = JSON.stringify({
        domain: {
            name: getRouterPermitName(routerAdr),
            version: '1',
            chainId: '250',
            verifyingContract: pairAdr,
        },
        message: {
            owner: from,
            spender: routerAdr,
            value: liquidityAmount.toFixed(0, 1),
            nonce: nonce.toHexString(),
            deadline: deadline,
        },
        primaryType: 'Permit',
        types: {
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
                { name: 'owner', type: 'address' },
                { name: 'spender', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'deadline', type: 'uint256' },
            ]
        }
    })

    var method = 'eth_signTypedData_v4'



    var params = [from, msgParams]

    console.log('Params >>>> ' + msgParams)
    console.log('Account > ' + from)
    console.log('Method >> ' + method)

    console.log('Param array :' + params)

    let returnPermit: SignatureData | undefined

    await library.send('eth_signTypedData_v4', [account, msgParams])
        .then(splitSignature)
        .then((signature) => {
            returnPermit = {
                v: signature.v,
                r: signature.r,
                s: signature.s,
                deadline: deadline,
            }    
        })
        .catch((err) => {
            // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
            if (err?.code !== 4001) {
                console.log('Error detected, other than 4001')
                //approveCallback()
            }
        })

    return returnPermit
}






