import { LTSData } from "../types/ltsTypes";
import { fetchDepositedOnLTS } from "./userData/fetchDepositedOnLTS";


//Faire un promise all + map

export const getFilterByDeposit = async (ltsDatas: LTSData[], account: string) : Promise<LTSData[]> => {

    const tmpReturn: LTSData[] = []

    for await (const lts of ltsDatas)
    {
        const stakedOnStrat = await fetchDepositedOnLTS(lts.gVaultAdr, account)

        if(stakedOnStrat.gt(0))
            tmpReturn.push(lts)
    }


    return tmpReturn
}