import addresses from "../values/addresses.json";

export const getVaultManagerAddress = () => {
    return addresses.vaultManagerAdr
}

export const getWrappedBaseAdr = () => {
    return addresses.wrappedFTM
}

export const getSpookyRouterAdr = () => {
    return addresses.spookyRouterAdr
}

export const getSpookyFactoryAdr = () => {
    return addresses.spookyFactoryAdr
}

export const getChainLinkFTMPriceFeedAdr = () => {
    return addresses.chainlinkFTMPriceFeedAdr
}

export const getMultiCallAdr = () => {
    return addresses.multiCallAdr
}

export const getUSDCAdr = () => {
    return addresses.USDCAdr
}


