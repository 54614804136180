import { Box } from "@mui/system"
import ConnectButton from "../TopNavBar/components/ConnectButton";

export type CardConnectProps = {
    value: number,

}

export const CardConnect: React.FC<CardConnectProps> = ({ value }) => {


    return (
        <Box hidden={value !== 3}>
            <Box sx={{ p: 1, }}>
                <ConnectButton />
            </Box>
        </Box>
    )
};

export default CardConnect;