import BigNumber from "bignumber.js"
import { getERC20Contract } from "../../utils/getContract"

export const fetchApprove = async (tokenAdr: string, owner: string, spender: string ) : Promise<BigNumber> => {

    const erc20Contract = getERC20Contract(tokenAdr)

    const approveValue = await erc20Contract.allowance(owner, spender)

    return new BigNumber(approveValue.toString())
}