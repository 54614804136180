import BigNumber from "bignumber.js";
import { getGasEstimateAndRun } from "../getGasEstimate";

export const callGiverVaultDeposit = async (giverVaultContract: any, amount: BigNumber) => {

    const value = amount.toString()

    console.log('Value : ' + value)

    const returnValue = await getGasEstimateAndRun(giverVaultContract, "deposit(uint256)", [value], 1100 )

    return returnValue
}



export const callGiverVaultPremiumDeposit = async (giverVaultContract: any, amount: BigNumber) => {

    const value = amount.toString()

    console.log('Value : ' + value)

    const returnValue = await getGasEstimateAndRun(giverVaultContract, "premiumDeposit(uint256)", [value], 1100 )

    return returnValue
}