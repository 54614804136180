import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { calculateLiquidity } from "../../functions/calculateLiquidity";
import { LTSData } from "../../types/ltsTypes";
import { useGiverVaultDeposit, useGiverVaultPremiumDeposit } from "../../hooks/actionHooks/useGiverVaultDeposit";
import useGiverVaultWithdraw from "../../hooks/actionHooks/useGiverVaultWithdraw";
import { useTokenApprove } from "../../hooks/actionHooks/useTokenApprove";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useTokenAllowance from "../../hooks/useTokenAllowance";
import { TokenBalanceData } from "../../hooks/useTokenBalance";
import { formatNumberTo2DeciFloor } from "../../utils/formatNumber";
import { BIG_POW18 } from "../../utils/utilsBigNumber";
import { OutLinedSmallClaimButton, RadFiPromiseButton } from "../StyledComponent/Buttons";
import { LegendNumber, LegendNumberInline } from "../StyledComponent/LegendNumber";
import TokenTextField from "./components/TokenTextField";
import { styleCardLiqBorder } from "./style/styled";
import { useGiverVaultClaim } from "../../hooks/actionHooks/useGiverVaultAction";
import { DialogFarmDeposit } from "../Dialogs/DialogFarmDeposit";

export type CardFarmProps = {
    ltsData: LTSData,
    value: number,
    stakedOnManager: TokenBalanceData,
    lpBalance: TokenBalanceData,
    withdrawableReward: TokenBalanceData,
}
export const CardFarm: React.FC<CardFarmProps> = ({ ltsData, value, stakedOnManager, lpBalance, withdrawableReward }) => {

    const { account } = useActiveWeb3React()

    const readableStaked = stakedOnManager.balance.div(BIG_POW18)

    const [withdrawValue, setWithdrawValue] = useState<BigNumber>(new BigNumber(0))

    const readableBalanceLP = lpBalance.balance.div(BIG_POW18)

    const [depositValue, setDepositValue] = useState<BigNumber>(new BigNumber(0))

    const approveLP = useTokenAllowance(ltsData.gWantAdr, account, ltsData.gVaultAdr)

    const { onApprove } = useTokenApprove(ltsData.gWantAdr, ltsData.gVaultAdr)
    const { onWithdraw } = useGiverVaultWithdraw(ltsData.gVaultAdr)
    const { onDeposit } = useGiverVaultDeposit(ltsData.gVaultAdr)
    const {onPremiumDeposit} = useGiverVaultPremiumDeposit(ltsData.gVaultAdr)
    const { onClaim } = useGiverVaultClaim(ltsData.gVaultAdr)

    const [openDialog, setOpen] = useState(false);

    const userReward = calculateLiquidity(withdrawableReward.balance, ltsData.iWanttokenPrice)


    const createStakedString = () => {


        if (stakedOnManager.balance.gt(0)) {
            const stakedReadable = stakedOnManager.balance.div(BIG_POW18)
            const userLiquidity = calculateLiquidity(stakedOnManager.balance, ltsData.gWantTokenPrice)
            return 'LP' + formatNumberTo2DeciFloor(stakedReadable) + ' ($' + formatNumberTo2DeciFloor(userLiquidity) + ')'
        }
        else {
            return '___'
        }

    }

    const handleApproveButton = async () => {
        const result = await onApprove()
        console.log(result)
        approveLP.callApproveRefresh()
    }

    const handleWithdrawButton = async () => {
        console.log('Selected value for withdraw is ' + withdrawValue.toString())

        await onWithdraw(withdrawValue)

        stakedOnManager.refresher()
        lpBalance.refresher()
    }

    const handleDepositButton = async () => {
        console.log('Selected value for deposit is ' + depositValue.toString())

        await onDeposit(depositValue)

        stakedOnManager.refresher()
        lpBalance.refresher()
    }

    const handlePremiumDepositButton = async () => {
        console.log('Selected value for premium deposit is ' + depositValue.toString())

        await onPremiumDeposit(depositValue)

        stakedOnManager.refresher()
        lpBalance.refresher()
    }

    const handleClaimButton = async () => {
        console.log('Claim action called')

        await onClaim()

        withdrawableReward.refresher()
    }

    const handleDialogOpen = () => {
        setOpen(true);
    }
    const handleDialogClose = () => {
        setOpen(false)
    }


    const disableDepositButton = depositValue.gt(lpBalance.balance) || depositValue.eq(0)
    const disableWithdrawButton = withdrawValue.gt(stakedOnManager.balance) || withdrawValue.eq(0)

    const choosenButtons = () => {
        if (approveLP.approve.gt(0)) {
            // return (<OutlinedNoIconButtonPromise promiseAction={handleDepositButton} label='DEPOSIT' disabled={disableDepositButton} />)
            return (<RadFiPromiseButton promiseAction={handleDialogOpen} label='DEPOSIT' disabled={disableDepositButton} />)
        }
        else {
            return (<RadFiPromiseButton promiseAction={handleApproveButton} label='APPROVE' />)
        }
    }


    const labelDeposit = <LegendNumberInline legend='in wallet: ' number={'LP ' + formatNumberTo2DeciFloor(readableBalanceLP)} />
    const labelWithdraw = <LegendNumberInline legend='in farm: ' number={'LP ' + formatNumberTo2DeciFloor(readableStaked)} />

    return (
        <Box hidden={value !== 2}>
            <Box sx={{ display: 'flex', mx: 1 }}>
                <Typography variant='h5' sx={{ p: 0 }}>DEPOSIT LP-TOKENS, AND FARM THEM!</Typography>
            </Box>
            <Grid container>
                <Grid item sx={{ order: { xs: 1, md: 0 } }} xs={6} md={4}>
                    <Box sx={{ p: 1 }}>

                        <TokenTextField maxValue={lpBalance.balance} tokenDeci={18} valueSetter={setDepositValue} label={labelDeposit} />
                        <Box sx={{ my: 1 }}>
                            {choosenButtons()}
                            <DialogFarmDeposit open={openDialog} handleClose={handleDialogClose} promiseAction={handleDepositButton} promisePremiumAction={handlePremiumDepositButton} amount={depositValue} pendingHarvest={userReward}/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sx={{ order: { xs: 0, md: 1 } }} xs={12} md={4}>
                    <Box sx={{ display: { xs: 'flex', md: 'block' }, justifyContent: 'space-evenly', alignItems: 'center', p: 1, m: 1, ...styleCardLiqBorder }}>
                        {/* <Typography variant='body1'>Stats: </Typography> */}
                        <LegendNumber legend='staked LP:' number={createStakedString()} />
                        <LegendNumber legend='claimable reward:' number={'USDC ' + formatNumberTo2DeciFloor(userReward)} />
                        <OutLinedSmallClaimButton promiseAction={handleClaimButton} label='CLAIM' />
                    </Box>
                </Grid>
                <Grid item sx={{ order: { xs: 2, md: 2 } }} xs={6} md={4}>
                    <Box sx={{ p: 1 }}>
                        <TokenTextField maxValue={stakedOnManager.balance} tokenDeci={18} valueSetter={setWithdrawValue} label={labelWithdraw} />
                        <Box sx={{ my: 1 }}>
                            <RadFiPromiseButton promiseAction={handleWithdrawButton} label='WITHDRAW' disabled={disableWithdrawButton} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
};

export default CardFarm;
