import BigNumber from "bignumber.js"
import { getRadFiMinterContract } from "../utils/getContract"


export const getMinterLastTimestamp = async (minterAdr : string) => {

    const radFiMinter = getRadFiMinterContract(minterAdr)

    const lastTS = await radFiMinter.lastRequestTimeStamp()

    return new BigNumber(lastTS.toString())

}