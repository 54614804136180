import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import useAuth from "../../../hooks/useAuth";
import { styleConnectButton } from "../style/styled";



export const ConnectButton: React.FC = () => {

    const { login, } = useAuth()

    const { active, account } = useWeb3React()

    return (
        <Box>
            <Button sx={styleConnectButton} startIcon={<AccountBalanceWalletOutlined />} color='secondary' variant='outlined' size='small' onClick={login}>
                <Typography variant='button' noWrap>{active ? account : 'CONNECT'}</Typography>
            </Button>
        </Box>
    )
};

export default ConnectButton;