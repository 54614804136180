import BigNumber from "bignumber.js";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { callGiverVaultDeposit, callGiverVaultPremiumDeposit } from "../../functions/userActions/callGiverVaultDeposit";
import { useSpecificGiverVault } from "../useContract";


export const useGiverVaultDeposit = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleDeposit = useCallback(
        async (amount: BigNumber) => {
            const txHash = await callGiverVaultDeposit(giverVault, amount)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('Deposited LP !', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onDeposit: handleDeposit }
};

export const useGiverVaultPremiumDeposit = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handlePremiumDeposit = useCallback(
        async (amount: BigNumber) => {
            const txHash = await callGiverVaultPremiumDeposit(giverVault, amount)

            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('Deposited LP !', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onPremiumDeposit: handlePremiumDeposit }
};