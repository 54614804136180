import { useMemo } from "react"
import { getERC20Contract, getSpecificRouterContract, getVaultManagerContract, getGiverVaultContract, getIndexedVaultContract } from "../utils/getContract"
import useActiveWeb3React from "./useActiveWeb3React"


export const useVaultManager = () => {
    const { library } = useActiveWeb3React()

    return useMemo(() => getVaultManagerContract(library?.getSigner()), [library])
}

export const useSpecificRouter = (routerAdr: string) => {
    const { library } = useActiveWeb3React()

    return useMemo(() => getSpecificRouterContract(routerAdr, library?.getSigner()), [routerAdr, library])
}

export const useSpecificGiverVault = (giverVaultAdr: string) => {
    const { library } = useActiveWeb3React()

    return useMemo(() => getGiverVaultContract(giverVaultAdr, library?.getSigner()), [giverVaultAdr, library])
}

export const useSpecificIndexedVault = (indexedVaultAdr: string) => {
    const { library } = useActiveWeb3React()

    return useMemo(() => getIndexedVaultContract(indexedVaultAdr, library?.getSigner()), [indexedVaultAdr, library])
}


export const useTokenContract = (tokenAdr: string) => {
    const { library } = useActiveWeb3React()

    return useMemo(() => getERC20Contract(tokenAdr, library?.getSigner()), [tokenAdr, library])
}