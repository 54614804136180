import BigNumber from "bignumber.js"
import { Call, multicallv2 } from "../../utils/multicall"
import uniswapV2PairAbi from "../../values/abi/abi_uniswapV2Pair.json";

export type LPData = {
    lpReserveToken0: BigNumber,
    lpReserveToken1: BigNumber,

    totalSupply: BigNumber,

    token0Adr: string,
    token1Adr: string,
}

const fetchLiquidityPool = async (lpAdr: string): Promise<LPData> => {

 
    const calls : Call[] = ['getReserves', 'totalSupply', 'token0', 'token1'].map((method) => {

        return {
            address: lpAdr,
            name: method,
        }
    })

    const multiResult = await multicallv2(uniswapV2PairAbi, calls)


    const [[_reserve0, _reserve1, ], [totalSupply], [token0], [token1]] = multiResult

    const _bnR0 = new BigNumber(_reserve0.toString())
    const _bnR1 = new BigNumber(_reserve1.toString())
    const _bnTS = new BigNumber(totalSupply.toString())

    return {
        lpReserveToken0: _bnR0,
        lpReserveToken1: _bnR1,
        totalSupply: _bnTS,
        token0Adr: token0,
        token1Adr: token1,
    }

}

export default fetchLiquidityPool