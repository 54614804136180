import giverVaultAbi from "../../values/abi/abi_giverVault.json";

import { multicallv2 } from "../../utils/multicall"
import BigNumber from "bignumber.js";


export type GiverVaultData = {
    gWantAdr: string,
    gMasterChefAdr: string,
    gMasterChefPid: number,
    gEarn: string,
    gRouterAdr: string,
    gTotalShares: BigNumber,
    iVaultAdr: string,
    owner: string,
    radFiMinter: string,
}

//IVault Adr
export const fetchGiverVault = async (giverVaultAdr: string): Promise<GiverVaultData> => {

    const calls = ['gWant', 'gMasterChef', 'gMasterChefPid', 'gEarn', 'gRouter', 'getGTotalShares', 'iVault', 'owner', 'radfiMinter'].map((method) => {
        return {
            address: giverVaultAdr,
            name: method
        }
    })

    const [[gWant], [gMasterChef], [gMasterChefPid], [gE], [gRouter], [GTotalShares], [iVault], [owner], [radfiMinter]] = await multicallv2(giverVaultAbi, calls)

    const gMCPID = parseInt(gMasterChefPid)

    const bNTotalShares = new BigNumber(GTotalShares.toString())

    return ({
        gWantAdr: gWant,
        gMasterChefAdr: gMasterChef,
        gMasterChefPid: gMCPID,
        gEarn: gE,
        gRouterAdr: gRouter,
        gTotalShares: bNTotalShares,
        iVaultAdr: iVault,
        owner: owner,
        radFiMinter: radfiMinter
    })
}