import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";

import { useEffect, useRef, useState } from "react";
import { simpleRPCProvider } from "../utils/simpleRPCProvider";
import { appDefaultChainID } from "../values/defaultValues";


const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {

    //Get current library, chainId,... of injected provider if exisiting
    const { library, chainId, ...web3React } = useWeb3React()
    //Caching current library
    const refLib = useRef(library)
    //Setting base state -> lib if existing or a default one (FTM RPC BASE). It always return a provider.
    const [provider, setProvider] = useState(library || simpleRPCProvider)

    useEffect(() => {
        if (library !== refLib.current) {
            setProvider(library || simpleRPCProvider)
            refLib.current = library
        }
    }, [library])

    return { library: provider, chainId: chainId ?? appDefaultChainID, ...web3React }
};

export default useActiveWeb3React;