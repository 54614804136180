import BigNumber from "bignumber.js";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { callGiverVaultWithdraw } from "../../functions/userActions/callGiverVaultWithdraw";
import { useSpecificGiverVault } from "../useContract";


export const useGiverVaultWithdraw = (giverVaultAdr: string) => {

    const giverVault = useSpecificGiverVault(giverVaultAdr)

    const {enqueueSnackbar} = useSnackbar()

    const handleWithdraw = useCallback(
        async (amount: BigNumber) => {
            const txHash = await callGiverVaultWithdraw(giverVault, amount)
            
            console.info('TRX result is : '  + txHash)

            if(txHash.toString() === '1'){
                enqueueSnackbar('LP tokens withdrawn!', {variant:'success'})
            }
        },
        [giverVault, enqueueSnackbar],
    )

    return { onWithdraw: handleWithdraw }
};

export default useGiverVaultWithdraw;