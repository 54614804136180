import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import BigNumber from "bignumber.js"
import React from "react"
import { useGiverVaultRewardAction } from "../../hooks/actionHooks/useGiverVaultAction"
import useActiveWeb3React from "../../hooks/useActiveWeb3React"
import { useCanMint, useMinterLastTimestamp } from "../../hooks/useCanMint"
import { usePendingReward } from "../../hooks/usePendingReward"
import { LTSData } from "../../types/ltsTypes"
import { formatNumberToXDeciFloor } from "../../utils/formatNumber"
import { BIG_POW18 } from "../../utils/utilsBigNumber"
import { RadFiPromiseButtonSmall } from "../StyledComponent/Buttons"
import MaterialCard from "../StyledComponent/MaterialCard"


export type BountyCardProps = {
    ltsData: LTSData,
}


export const BountyCard: React.FC<BountyCardProps> = ({ ltsData }) => {
    //Nom de la LTS

    //Gain en RadFi + FTM ? 

    // Bouton avec compte à rebour dessus

    const pendingReward = usePendingReward(ltsData.gMasterChefAdr, ltsData.gMasterChefPid, ltsData.gVaultAdr, ltsData.gEarnAdr)
    const pendingRewardCoumpound = usePendingReward(ltsData.iMasterChefAdr, ltsData.iMasterChefPid, ltsData.iVaultAdr, ltsData.iEarnAdr)
    const canMintBool = useCanMint(ltsData.radFiMinter)
    const lastTimestamp = useMinterLastTimestamp(ltsData.radFiMinter)

    const pendingPrice = pendingReward.times(ltsData.gEarnTokenPrice).times(0.018).div(BIG_POW18)
    const pendingPriceCoumpound = pendingRewardCoumpound.times(ltsData.gEarnTokenPrice).times(0.018).div(BIG_POW18)

    // const {onGFarm} = useGiverVaultGFarm(ltsData.gVaultAdr)
    // const {onGiverCompound} = useGiverVaultCompound(ltsData.gVaultAdr)
    const { onGiverReward } = useGiverVaultRewardAction(ltsData.gVaultAdr)

    const { account } = useActiveWeb3React()



    const showRemainingTime = () => {
        const now = Date.now()

        const timeDif = new BigNumber(now).minus(lastTimestamp.times(1000))

        const newDate = new Date(timeDif.toNumber())

        // console.log('HOURS = ' + newDate.getHours())
        // console.log('MINUTES = ' + newDate.getMinutes())
        // console.log('HOURS = ' + newDate.getSeconds())


      
        const seconds = newDate.getSeconds() > 60 ? '0' : (60 - newDate.getSeconds())

        return ((newDate.getHours() - 1).toString() + ":" + newDate.getMinutes() + ':' + seconds)
    }


    // const handleGFarmButton = async () => {
    //     console.log('GFarm Called')

    //     await onGFarm()
    // }

    // const handleGCompound = async () => {
    //     console.log('GCompoundCalled')

    //     await onGiverCompound()
    // }

    const handleRewardActionButton = async () => {
        console.log('Reward Action called')

        await onGiverReward()
    }

    return (
        <Box sx={{ display: 'block', justifyContent: 'center' }}>
            <MaterialCard sx={{ display: 'flex', flexWrap: 'wrap', py: 0.5, maxWidth: '120ch', }}>

                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <Typography sx={{ mx: 1 }} variant="h6">{ltsData.gToken0Infos.symbol}-{ltsData.gToken1Infos.symbol}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} display='flex' justifyContent='center' alignItems='center'>

                        <Typography sx={{ mx: 0.5 }} variant="body2" noWrap>reward:</Typography>
                        <Typography sx={{ mr: 0.5 }} variant="body1" noWrap>1 RadFi + ${formatNumberToXDeciFloor(pendingPrice.gte(pendingPriceCoumpound) ? pendingPrice : pendingPriceCoumpound, 9)}</Typography>
                        <Typography sx={{ mr: 1 }} variant="body2" noWrap>in {ltsData.iToken0Infos.symbol}-{ltsData.iToken1Infos.symbol}-LP token </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Box sx={{ mx: 1 }}>
                            <RadFiPromiseButtonSmall promiseAction={handleRewardActionButton} label={canMintBool ? "CLAIM" : showRemainingTime()} disabled={account===undefined}/>
                        </Box>
                    </Grid>
                </Grid>
            </MaterialCard >
        </Box>
    )
}