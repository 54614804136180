import { Grid, List } from "@mui/material";
import { Box } from "@mui/system";
import { calculateLiquidity } from "../../functions/calculateLiquidity";
import { LTSData } from "../../types/ltsTypes";
import { TokenBalanceData } from "../../hooks/useTokenBalance";
import { formatNumberTo2DeciFloor } from "../../utils/formatNumber";
import { getMasterChiefName } from "../../values/getMasterChefMethods";
import { LegendNumber } from "../StyledComponent/LegendNumber";
import { SummaryListItem } from "../StyledComponent/SummaryListItem";
import CalculateAPYLTS from "../../functions/calculateAPY_LTS";
import { getFixedApr } from "../../utils/getFixedApr";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import React from "react";
import {  RadFiPromiseButtonSmall } from "../StyledComponent/Buttons";
import { useGiverVaultGFarm, useGiverVaultMCDeposit, useIndexedVaultCompoundRoutine } from "../../hooks/actionHooks/useGiverVaultAction";

export type CardSummaryProps = {
    ltsData: LTSData,
    value: number,
    stakedOnManager: TokenBalanceData,
    withdrawableReward: TokenBalanceData
}

export const CardSummary: React.FC<CardSummaryProps> = ({ ltsData, value, stakedOnManager, withdrawableReward }) => {

    const { account } = useActiveWeb3React()

    const userLiquidity = calculateLiquidity(stakedOnManager.balance, ltsData.gWantTokenPrice)
    const userReward = calculateLiquidity(withdrawableReward.balance, ltsData.iWanttokenPrice)

    const APYData = CalculateAPYLTS(ltsData, getFixedApr(ltsData.gWantAdr))

    const isOwner = account === ltsData.owner

    const { onGFarm } = useGiverVaultGFarm(ltsData.gVaultAdr)

    const handleGFarmButton = async () => {
        console.log('GFarm action called')
        await onGFarm()
    }

    const { onMCDeposit } = useGiverVaultMCDeposit(ltsData.gVaultAdr)

    const handleMCDeposit = async () => {
        console.log('MC Deposit action called')
        await onMCDeposit()
    }

    const { onComRoutine } = useIndexedVaultCompoundRoutine(ltsData.iVaultAdr)

    const handleComRouButton = async () => {
        console.log('CompoundRoutine action called')
        await onComRoutine()
    }

    const userBoard = () => {
        if (isOwner) {
            return (<React.Fragment>
                <RadFiPromiseButtonSmall promiseAction={handleMCDeposit} label='MCDeposit' />
                <RadFiPromiseButtonSmall promiseAction={handleGFarmButton} label='GFarm' />
                <RadFiPromiseButtonSmall promiseAction={handleComRouButton} label='ICompound' />
            </React.Fragment>)
        }
        else {
            return (<React.Fragment>
                <LegendNumber legend='staked by user:' number={'$ ' + formatNumberTo2DeciFloor(userLiquidity)} />

                <LegendNumber legend='claimable reward:' number={'$ ' + formatNumberTo2DeciFloor(userReward)} />
            </React.Fragment>)
        }


    }

    return (
        <Box hidden={value !== 0} >
            <Grid container>
                <Grid item xs={6}>
                    <Box>
                        <List>
                            <SummaryListItem label={getMasterChiefName(ltsData.gMasterChefAdr)} toolTip={undefined} />
                            <SummaryListItem label="Autocompound, earn LP-Fees & USDC" toolTip={undefined} />
                            {/* <SummaryListItem label="Performance fees: 5%" toolTip='Fees taken on net benefits'/> */}
                            <SummaryListItem label={"APR breakdown: stable: " + formatNumberTo2DeciFloor(APYData.stableAPR) + "% + fees: " + formatNumberTo2DeciFloor(APYData.feesAPR) + "%"} toolTip={undefined} />
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ p: 1, height: '100%', alignContent: 'center' }}>
                        {userBoard()}
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
};

export default CardSummary;