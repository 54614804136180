import { Badge } from "@mui/material";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { chainName } from "../values/chainIDtoChainNames";

export const ChainNameBadge: React.FC= () => {
    console.log('Reload: ChainNameBadge')

    const {chainId,} = useActiveWeb3React()

    return (
        <Badge>{chainName(chainId)}</Badge>
    )
};

export default ChainNameBadge;