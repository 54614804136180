import BigNumber from "bignumber.js";
import { getWrappedBaseAdr } from "../../utils/getAddress";
import { getGasEstimateAndRun } from "../getGasEstimate";
import { SignatureData } from "./callPermitForWithdraw";

export const callRouterWithdraw = async (routerContract: any, amountLig: BigNumber, adr0: string, adr1: string, amount0: BigNumber, amount1: BigNumber, deadline: number) => {


    const signerAdr = await routerContract.signer?.getAddress()

    const isBase0 = adr0 === getWrappedBaseAdr()
    const isBase1 = adr1 === getWrappedBaseAdr()

    const minAmount0 = amount0.times(new BigNumber(0.99)).toFixed(0, 1)
    const minAmount1 = amount1.times(new BigNumber(0.99)).toFixed(0, 1)

    if (isBase0) {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidityETh', [adr1, amountLig.toFixed(0, 1), minAmount1, minAmount0, signerAdr, deadline], 1100)
        return returnValue
    }
    else if (isBase1) {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidityETh', [adr0, amountLig.toFixed(0, 1), minAmount0, minAmount1, signerAdr, deadline], 1100)
        return returnValue
    } else {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidity', [adr0, adr1, amountLig.toFixed(0, 1), minAmount0, minAmount1, signerAdr, deadline], 1100)
        return returnValue
    }
}

export const callRouterWithdrawWithPermit = async (routerContract: any, amountLig: BigNumber, adr0: string, adr1: string, amount0: BigNumber, amount1: BigNumber, sigData: SignatureData) => {


    const signerAdr = await routerContract.signer?.getAddress()

    const isBase0 = adr0 === getWrappedBaseAdr()
    const isBase1 = adr1 === getWrappedBaseAdr()

    const minAmount0 = amount0.times(new BigNumber(0.99)).toFixed(0, 1)
    const minAmount1 = amount1.times(new BigNumber(0.99)).toFixed(0, 1)

    if (isBase0) {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidityETHWithPermit', [adr1, amountLig.toFixed(0, 1), minAmount1, minAmount0, signerAdr, sigData.deadline, false, sigData.v, sigData.r, sigData.s], 1100)
        return returnValue
    }
    else if (isBase1) {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidityETHWithPermit', [adr0, amountLig.toFixed(0, 1), minAmount0, minAmount1, signerAdr, sigData.deadline, false, sigData.v, sigData.r, sigData.s,], 1100)
        return returnValue
    } else {
        const returnValue = await getGasEstimateAndRun(routerContract, 'removeLiquidityWithPermit', [adr0, adr1, amountLig.toFixed(0, 1), minAmount0, minAmount1, signerAdr, sigData.deadline, false, sigData.v, sigData.r, sigData.s], 1100)
        return returnValue
    }
}