import { Box, Typography } from "@mui/material";
import React from "react";

export type LegendNumberProps = {
    legend: string,
    number: string
}
export const LegendNumber: React.FC<LegendNumberProps> = ({ legend, number }) => {

    return (

        <Box sx={{ alignItems: 'center', }}>
            <Typography sx={{ lineHeight: 1.5 }} variant="body2">{legend}</Typography>
            <Typography sx={{ lineHeight: 1 }} variant="body1">{number}</Typography>
        </Box>

    )
};

export const LegendNumberInline: React.FC<LegendNumberProps> = ({ legend, number }) => {

    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">{legend}</Typography>
            <Typography sx={{ ml: 1 }} variant="body1" noWrap>{number}</Typography>
        </Box>

    )
};

export const LegendNumberImportant: React.FC<LegendNumberProps> = ({ legend, number }) => {

    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ lineHeight: 1.5 }} variant="body2">{legend}</Typography>
            <Typography sx={{ lineHeight: 1, ml: 1 }} variant="h6" >{number}</Typography>
        </Box>


    )

}