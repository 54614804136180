import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import getBalance from "../functions/getBalance";
import getBaseBalance from "../functions/getBaseBalance";
import { getWrappedBaseAdr } from "../utils/getAddress";
import useTimedRefresh from "./useTimedRefresh";


export type TokenBalanceData = {
    balance : BigNumber,
    refresher : () => void,
}

export const useTokenBalance = (tokenAdr: string, account: string | null | undefined, useWrapped?: boolean) : TokenBalanceData => {

    const {timer} = useTimedRefresh()

    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));


    const [manualRefresh, setManualRefresh] = useState<boolean>(true)

    const callBalanceRefresh = () => {
        setManualRefresh((prev) => !prev)
    }

    const checkWrapped = useWrapped ? useWrapped : false;

    useEffect(() => {
        const fetchBalance = async () => {
            const bal = await getBalance(tokenAdr, account as string)

            setBalance(bal)
        }

        const fetchBaseBalance = async () => {
            const bal = await getBaseBalance(account as string)

            setBalance(bal)
        }

        if (typeof account !== 'string') {
            setBalance(new BigNumber(0))
            return
        }

        if (checkWrapped) {
            fetchBalance()
        }
        else if (tokenAdr !== getWrappedBaseAdr())
            fetchBalance()
        else if (tokenAdr === getWrappedBaseAdr())
            fetchBaseBalance()

    }, [tokenAdr, account, timer, manualRefresh, checkWrapped])

    return {balance : balance, refresher : callBalanceRefresh}
};

export default useTokenBalance;