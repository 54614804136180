import BigNumber from "bignumber.js";
import { getGiverVaultContract, getIndexedVaultContract } from "../../utils/getContract";


export const fetchWithdrawable = async (gVaultAdr: string, iVaultAdr: string, account: string): Promise<BigNumber> => {

    //On fetch l'entry point et les shares de l'user
    const giverVault = getGiverVaultContract(gVaultAdr);

    //On call la fonction 

    const {shares, entryCounter} = await giverVault.users(account);

    const indexVault = getIndexedVaultContract(iVaultAdr);  

    const wAmount = await indexVault.withdrawableAmount(shares, entryCounter);

    return new BigNumber(wAmount.toString())
}