import { Web3ReactProvider } from "@web3-react/core";
import { SnackbarProvider } from "notistack";
import { TimedRefreshProvider } from "./contexts/TimedRefreshContext";
import { MainThemeProvider } from "./theming/mainThemeProvider";
import { getLibrary } from "./utils/web3React";



export const Providers: React.FC = ({ children }) => {

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <MainThemeProvider>
                <TimedRefreshProvider>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        {children}
                    </SnackbarProvider>
                </TimedRefreshProvider>
            </MainThemeProvider>
        </Web3ReactProvider>
    )
};

export default Providers;