import { useEffect, useState } from "react";
import { getFilterByDeposit } from "../functions/getFilterByDeposit";
import { LTSData } from "../types/ltsTypes";



export const useFilterByDeposit = (ltsDatas : LTSData[], filtered : boolean, account: string | null | undefined) => {

    const [filteredList, setFiltered] = useState<LTSData[]>([])

    useEffect( () => {

        const createFilteredList = async () => {

            let tmpFilteredList = await getFilterByDeposit(ltsDatas, account as string)

            setFiltered(tmpFilteredList)
        }

        if(filtered && typeof account === 'string')
            createFilteredList()
        else
            setFiltered(ltsDatas)



    }, [ltsDatas, filtered, account])

    return filteredList
}