import { getRadFiMinterContract } from "../utils/getContract"



export const getCanMint = async (minterAdr : string) => {

    const radFiMinter = getRadFiMinterContract(minterAdr)

    const canMintBool = await radFiMinter.canMint()

    return canMintBool as boolean


}