import { Web3Provider } from "@ethersproject/providers"
import BigNumber from "bignumber.js"
import { useCallback } from "react"
import { callPermitForWithdraw, SignatureData } from "../../functions/userActions/callPermitForWithdraw"
import { simpleRPCProvider } from "../../utils/simpleRPCProvider"
import useActiveWeb3React from "../useActiveWeb3React"



export const useCreatePermitOrApprove = (routerAdr: string, pairAdr: string) => {


    const { library, account } = useActiveWeb3React()

    const handlePermitOrApprove = useCallback(
        async (amountLiq: BigNumber): Promise<SignatureData | undefined> => {
            const blockNumber = await simpleRPCProvider.getBlockNumber()
            const blockTimeStamp = (await simpleRPCProvider.getBlock(blockNumber)).timestamp
            const deadline = blockTimeStamp + 600

            const permit = await callPermitForWithdraw(library as Web3Provider, account as string, routerAdr, pairAdr, amountLiq, deadline)

            if (permit !== undefined) {
                console.log('PERMIT IS DEFINED : ')
                console.log('V : ' + permit.v)
                console.log('R : ' + permit.r)
                console.log('S : ' + permit.s)
                console.log('Deadline : ' + permit.deadline)
            }

            return permit

        }, [library, account, routerAdr, pairAdr]
    )


    return { onPermit: handlePermitOrApprove }
}