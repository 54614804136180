
import { checkIfWrappedBaseSymbol } from "../../utils/checkIfWrappedBaseName"
import { multicallv2 } from "../../utils/multicall"
import erc20Abi from "../../values/abi/abi_erc20.json";

export type TokenData = {
    adr: string,
    symbol: string,
    decimals: number,
}

const fetchToken = async (tokenAdr: string): Promise<TokenData> => {

    const calls = ['symbol', 'decimals'].map((method) => {
        return {
            address : tokenAdr,
            name: method
        }
    })

    const multicallResult = await multicallv2(erc20Abi, calls)

    const [[symbol], [decimals]] = multicallResult

    const rSymbol = checkIfWrappedBaseSymbol(symbol)
    const rDeci = parseInt(decimals)

    return {
        adr: tokenAdr,
        symbol: rSymbol,
        decimals: rDeci,
    }
}

export default fetchToken