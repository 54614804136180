import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { fetchApprove } from "../functions/userData/fetchApprove";
import { getWrappedBaseAdr } from "../utils/getAddress";

export const useTokenAllowance = (tokenAdr: string, account: string | null | undefined, spender: string) => {

    const [approve, setApprove] = useState<BigNumber>(new BigNumber(0));

    const [manualRefresh, setManualRefresh] = useState<boolean>(true)

    const callApproveRefresh = () => {
        setManualRefresh((prev) => !prev)
    }

    useEffect(() => {
        const fetchTokenApprove = async () => {
            const bal = await fetchApprove(tokenAdr, account as string, spender)

            setApprove(bal)
        }

        const fetchBaseApprove = async () => {
            const bal = new BigNumber('10000000000000000000000000000000000000000')

            setApprove(bal)
        }

        if (typeof account !== 'string') {
            setApprove(new BigNumber(0))
            return
        }

        console.log('TOKEN ADR CHANGED')

        if (tokenAdr !== getWrappedBaseAdr())
            fetchTokenApprove()
        else if (tokenAdr === getWrappedBaseAdr())
            fetchBaseApprove()

    }, [tokenAdr, account, spender, manualRefresh ])

    return {approve, callApproveRefresh}
};

export default useTokenAllowance;