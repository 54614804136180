import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { getCanMint } from "../functions/getCanMint";
import { getMinterLastTimestamp } from "../functions/getMinterLastTimestamp";
import useTimedRefresh from "./useTimedRefresh";



export const useCanMint = (minterAdr : string) => {

    const {fastTimer} = useTimedRefresh()

    const [canMint, setCanMint] = useState<boolean>(false);

    useEffect(() => {
        
        const fetchCanMint = async () => {

                const pendingReward = await getCanMint(minterAdr)

                setCanMint(pendingReward)
        }

        fetchCanMint()
    }, [fastTimer, minterAdr])

    return canMint
}

export const useMinterLastTimestamp = (minterAdr : string ) => {
    const {fastTimer} = useTimedRefresh()

    const [lastTimestamp, setLastTimestamp] = useState<BigNumber>(new BigNumber(0))

    useEffect(() => {
        const fetchLastTimestamp = async () => {
            const lastTs = await getMinterLastTimestamp(minterAdr)

            setLastTimestamp(lastTs)
        }

        fetchLastTimestamp()
    }, [fastTimer, minterAdr])

    return lastTimestamp
}