
import { ethers } from "ethers";
const POLLING_INTERVAL = 12000

//Create a new provider, change it's pollingInterval and return it.

export const getLibrary = (provider:  ethers.providers.ExternalProvider | ethers.providers.JsonRpcFetchFunc): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
}