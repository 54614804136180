




export const checkIfWrappedBaseSymbol = (symbolToCheck: string): string => {

    if (symbolToCheck === 'WFTM')
        return 'FTM'

    if (symbolToCheck === 'WBNB')
        return 'BNB'

    return symbolToCheck


}