import getBalance from "../getBalance"
import getLPTokenValueInUSD from "../getLPTokenValueInUSD"
import { getTokenValueInUSD } from "../getTokenValueInUSD"
import fetchLiquidityPool from "../strategies/fetchLiquidityPool"
import { combineStaticAndDynamic } from "./combineStaticAndDynamic"
import { fetchGiverVaultDynamic } from "./fetchGiverVaultDynamic"
import { LTSData, LTSStaticData } from "../../types/ltsTypes"

//Prend les statics datas, et va fetch les datas dynamiques
export const fetchLTSDynamicData = async (ltsData: LTSStaticData): Promise<LTSData> => {

    const [gWantInfos, gMCEarnTotalShares, gTotalShares, gEarnTokenPrice, gWantTokenPrice, iWantTokenPrice ] = await Promise.all([
        fetchLiquidityPool(ltsData.gWantAdr),
        getBalance(ltsData.gWantAdr, ltsData.gMasterChefAdr),
        fetchGiverVaultDynamic(ltsData.gVaultAdr),
        getTokenValueInUSD(ltsData.gEarnAdr),
        getLPTokenValueInUSD(ltsData.gWantAdr, ltsData.gToken0Infos.adr, ltsData.gToken1Infos.adr, ltsData.gToken0Infos.decimals, ltsData.gToken1Infos.decimals),
        getLPTokenValueInUSD(ltsData.iWantAdr, ltsData.iToken0Infos.adr, ltsData.iToken1Infos.adr, ltsData.iToken0Infos.decimals, ltsData.iToken1Infos.decimals)
    ])

    return combineStaticAndDynamic(ltsData, {
        gWantReserve0: gWantInfos.lpReserveToken0,
        gWantReserve1: gWantInfos.lpReserveToken1,
        gWantTotalSupply: gWantInfos.totalSupply,
        gWantTokenPrice: gWantTokenPrice,

        iWanttokenPrice: iWantTokenPrice,

        gVaultTotalShares: gTotalShares,

        gEarnTokenPrice: gEarnTokenPrice,
        gMCEarnTotalShares: gMCEarnTotalShares,
    })
}