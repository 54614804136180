import { MaxUint256 } from '@ethersproject/constants'
import { getGasEstimate } from '../getGasEstimate'
import { getGasPrice } from "../getGasPrice"



export const callTokenApprove = async (tokenContract: any, spender: string) => {

    const marginGasLimit = await getGasEstimate(tokenContract, 'approve', [spender, MaxUint256], 1100)

    const options = {
        gasLimit: marginGasLimit,
    }

    const gasPrice = (await getGasPrice()).toString()

    const tx = await tokenContract.approve(spender, MaxUint256, { ...options, gasPrice })

    const receipt = await tx.wait()

    return receipt.status
}