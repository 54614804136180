import BigNumber from "bignumber.js"
import { getChainLinkFTMPriceFeedAdr } from "../utils/getAddress"
import { getChainLinkPriceFeedContract } from "../utils/getContract"
import { BIG_POW8 } from "../utils/utilsBigNumber"


const getBaseTokenPriceInUSD = async (): Promise<BigNumber> => {

    const FTMPriceFeedContract = getChainLinkPriceFeedContract(getChainLinkFTMPriceFeedAdr())

    const {answer, } = await FTMPriceFeedContract.latestRoundData()

  
    return (new BigNumber(answer.toString())).div(BIG_POW8)
}

export default getBaseTokenPriceInUSD