
import './App.css';
import CardsDisplay from './compositions/CardsDisplay';
import TopNavBar from './compositions/TopNavBar/TopNavBar';
import Providers from './Providers';

// import '@fontsource/bebas-neue'
// import '@fontsource/dosis'
import { CssBaseline } from '@mui/material';
import { useState } from 'react';

function App() {

  const menuState = useState<number>(0)

  return (
    <div className="App">
      <Providers>
        <CssBaseline />
        <TopNavBar menuState={menuState} />
        <CardsDisplay menuState={menuState[0]}/>
      </Providers>
    </div>
  );
}

export default App;
