import BigNumber from "bignumber.js";
import { getSpookyFactoryAdr, getWrappedBaseAdr } from "../utils/getAddress";
import { getPairContract, getSpecificFactoryContract } from "../utils/getContract";


const getTokenValueInBase = async (tokenAdr: string, factoryAdr?: string): Promise<BigNumber> => {

    if (tokenAdr === getWrappedBaseAdr()) {
        return new BigNumber('1')
    }

    const currentFactoryAdr = factoryAdr ?? getSpookyFactoryAdr()

    const factoryContract = getSpecificFactoryContract(currentFactoryAdr)

    //Obtenir via le router les reserves de la pool Token/FTM
    const tokenFTMPairAdr = await factoryContract.getPair(getWrappedBaseAdr(), tokenAdr)

    const pairContract = getPairContract(tokenFTMPairAdr)

    const [{ _reserve0, _reserve1 }, token0Adr] = await Promise.all([
        pairContract.getReserves(),
        pairContract.token0()
    ])

    const tokenPrice = token0Adr === tokenAdr ? 
    (new BigNumber(_reserve1.toString())).div(new BigNumber(_reserve0.toString())) :
    (new BigNumber(_reserve0.toString())).div(new BigNumber(_reserve1.toString()))

   return tokenPrice  
}

export default getTokenValueInBase