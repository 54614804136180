import BigNumber from "bignumber.js";
import { getGiverVaultContract, } from "../../utils/getContract";


export const fetchDepositedOnLTS = async (ltsAdr: string, account: string): Promise<BigNumber> => {


    const giverVaultContract = getGiverVaultContract(ltsAdr)

    const {shares,} = await giverVaultContract.users(account)

    return new BigNumber(shares.toString())
}