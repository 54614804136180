import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

export const lightTheme = createTheme({
    palette: {
        primary: { main: '#E5455e' },
        secondary: { main: '#f1c681' },
        neutral: {
            main: '#cccccc',
            dark: '#aaaaaa',
        },

        background: {
            default: grey[100],
        }

    },
    typography: {
        fontFamily: ['Bebas Neue, Dosis'].join(','),
        fontSize: 18,


        h1: {
            fontSize: 38,
            fontFamily: 'Bebas Neue',
        },
        h3: {
            fontSize: 30,
            fontFamily:'Dosis',
        },
        h5: {
            color: '#cccccc',
            fontFamily: 'Bebas Neue',
            fontSize: 20,
            lineHeight: 1,
        },
        h6: {
            fontSize: 30,
            fontFamily: 'Bebas Neue',
            color: '#E5455e',
        },
        button: {
            fontFamily: 'Dosis',
        },
        body2: {
            fontFamily: 'Dosis',
            color: '#ababab'
        }

    },
    spacing: 8,
})