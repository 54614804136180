import { ArrowRightRounded, HelpOutlineRounded } from "@mui/icons-material"
import { ListItem, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"


export type SummaryListProps = {
    label: string,
    toolTip: undefined | string,
}

export const SummaryListItem: React.FC<SummaryListProps> = ({ label, toolTip }) => {


    const toolTypeIcon = () => {
        if (toolTip)
            return (
                <Tooltip title={toolTip as string} arrow>
                    <HelpOutlineRounded sx={{ ml: 1, height: 16, width: 16 }} color='secondary' />
                </Tooltip>
            )

    }

    return (
        <ListItem disablePadding>
            <Box sx={{ display: 'flex', alignItems: 'center', m: 0, p: 0 }}>
                <ArrowRightRounded sx={{ mr: 3, height: 20, width: 20 }} color='secondary' />
                <Typography sx={{color:'black'}} variant='body2' noWrap>{label}</Typography>
                {toolTypeIcon()}
            </Box>
        </ListItem>
    )

}