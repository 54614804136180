import { Theme } from "@mui/material"

//Global Box of the AppBar
export const styleAppBarGlobal = {
    boxShadow: 0,
    bgcolor : 'black',
    padding: (theme: Theme) => theme.spacing(2),
}

//Container of Title, and RightBox (Connect Button ,etc..)
export const styleAppBarInsideBox = {
    display : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

//Right Box 
export const styleAppBarRightBox = {
    display: 'flex',
    alignItems: 'center'
}

//Style of connect Button
export const styleConnectButton = {
    width: '12ch',
    border: '3px solid',
    borderRadius: 8,
    fontSize: 22,
    mx: (theme: Theme) => theme.spacing(2),

    '&:hover' : {
        border: '3px solid',
    }
}

