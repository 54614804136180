
import { FetchingError } from "../../types/errorEnum"
import { LTSData, LTSStaticData } from "../../types/ltsTypes"
import { fetchLTSDynamicData } from "./fetchLTSDynamicData"

export const fetchAllLTSDynamic = async (staticData: LTSStaticData[]): Promise<[LTSData[], FetchingError]> => {

    // try {
        const allLTS: LTSData[] = await Promise.all(staticData.map((adr: LTSStaticData) => fetchLTSDynamicData(adr)))
        return [allLTS, FetchingError.SUCCESS]

    // }
    // catch {
    //     console.log('Error while retrieving dynamic data')
    //     return [[], FetchingError.ERROR]
    // }


}